import React from 'react';
import ContextMenu from 'Common/Components/ContextMenu';
import { Separator } from 'Common/Components/ContextMenu/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowContextMenu, selectContextMenuXPos, selectContextMenuYPos, selectVulnsSelected, selectPermissions
} from 'store/Manage/selectors';
import { showContextMenu } from 'store/Manage/actions';
import get from 'lodash/get';
import {
  AddNewEvidence, Delete, Edit, Filter, Severity, Status, CopyLink, CopyApiLink, SaveAsTemplate
} from './components';
import ApplyTemplate from './components/ApplyTemplate';
import { selectReadOnly } from 'store/Faraday/selectors';

const VulnsContextMenu = () => {
  const dispatch = useDispatch();
  const displayContextMenu = useSelector(selectShowContextMenu);
  const xPos = useSelector(selectContextMenuXPos);
  const yPos = useSelector(selectContextMenuYPos);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isBulk = vulnsSelected.length > 1;
  const areVulnsSelected = vulnsSelected.length > 0;
  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const cantUpdate = !get(permissions, 'update.allowed', false);

  if (readOnly || cantUpdate || !displayContextMenu || !areVulnsSelected) return null;
  return (
    <ContextMenu
      show={ displayContextMenu }
      xPos={ xPos }
      yPos={ yPos }
      displayFunction={ (show, x, y) => dispatch(showContextMenu(show, x, y)) }
    >
      <Filter />
      <Separator show={ !isBulk && areVulnsSelected } />
      <CopyLink />
      <CopyApiLink />
      <Edit />
      <Severity />
      <Status />
      <AddNewEvidence />
      <Separator show />
      <SaveAsTemplate />
      <ApplyTemplate />
      <Separator show />
      <Delete />
    </ContextMenu>
  );
};

export default VulnsContextMenu;
