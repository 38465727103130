import React from 'react';
import FlagIcon from 'Common/Components/FlagIcon/index';
import { updateVuln } from 'store/Manage/actions';
import { useSelector, useDispatch } from 'react-redux';
import { selectVulnDetail } from 'store/Manage/selectors';
import get from 'lodash/get';
import { Wrapper, Label } from './styled';
import { selectReadOnly } from 'store/Faraday/selectors';

const DisplayConfirmed = () => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);
  const confirmed = get(vuln, 'confirmed', false);
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;
  const handleClick = () => {
    if (canUpdate) dispatch(updateVuln(vuln, 'confirmed', !confirmed));
  };
  return (
    <Wrapper>
      <FlagIcon
        theme={ confirmed ? 'confirmed' : 'notConfirmed' }
        alt={ confirmed ? 'Confirmed' : 'Un-confirmed' }
        onClick={ handleClick }
        aria-label={ confirmed ? 'Confirmed' : 'Not confirmed' }
      />
      <Label>{confirmed ? 'Confirmed' : 'Not Confirmed'}</Label>
    </Wrapper>
  );
};

export default DisplayConfirmed;
