import React from 'react';
import { useDispatch } from 'react-redux';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_AGENT_CREATE, MODAL_AGENT_TOKEN } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import TokenButton from '../ActionBar/components/TokenButton';
import { LegacyWrapper, ButtonWrapper, Title } from '../ActionBar//styled';
import Token from '../ActionBar/components/TokenButton/components/Token';
import CreateButton from '../ActionBar/components/CreateButton';
import CreateAgentMenu from '../CreateNewAgent';
import { FormattedMessage } from 'react-intl';
import { GridViewButton, ListViewButton } from '../ActionBar/components/ViewButtons';

const LegacyActionBar = ({ onToggleView, showGridView, showCloudAgents }) => {
  const dispatch = useDispatch();
  return (
    <LegacyWrapper>
      <Title children={ <FormattedMessage id='agents.title' /> } />
      <ButtonWrapper>
        {
          !showCloudAgents &&
          <>
            <CreateButton showSeparator />
            <TokenButton onClick={ () => dispatch(openModal(MODAL_AGENT_TOKEN)) } />
          </>
        }
        {showGridView
          ? (
          <ListViewButton showGridView={ showGridView } onToggleView={ onToggleView } />
            )
          : (
          <GridViewButton showGridView={ showGridView } onToggleView={ onToggleView } />
            )}
      </ButtonWrapper>
      <ReduxModal id={ MODAL_AGENT_TOKEN }>
        <Token />
      </ReduxModal>
      <ReduxModal id={ MODAL_AGENT_CREATE }>
        <CreateAgentMenu onClose={ () => dispatch(closeModal(MODAL_AGENT_CREATE)) } />
      </ReduxModal>
    </LegacyWrapper>
  );
};
export default LegacyActionBar;
