import styled from 'styled-components';
import colors from 'Styles/colors';

/* eslint-disable import/prefer-default-export */
export const ViewAll = styled.a`
  color: ${colors.blueCerulean};
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  line-height: 2.62;
`;
ViewAll.displayName = 'ViewAll';
