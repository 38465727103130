import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRow } from 'Screens/Contextualization/Workspaces/actions/Actions';
import Checkbox from 'Common/Components/Checkbox';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';

const CheckColum = ({ ws }) => {
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const dispatch = useDispatch();
  const isSelected = workspacesSelected.find((workspaceSelected) => workspaceSelected._id === ws._id);

  const handleClick = (e) => dispatch(selectRow(e, ws));
  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};

export default CheckColum;
