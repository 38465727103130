import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  grid-row: 2/3;
  display: flex;
  padding: 0 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Item = styled.button`
  padding: 14px 29px;
  background-color: ${({ isActive }) => isActive ? colors.white : colors.iceBlue};
  border: none;
  border-left: 1px solid ${colors.white};
  border-right: 1px solid ${colors.white};
  border-top: ${({ isActive }) => isActive ? `1px solid ${colors.white}` : `1px solid ${colors.iceBlue}`};
  border-bottom: ${({ isActive }) => isActive ? `1px solid ${colors.white}` : `1px solid ${colors.iceBlue}`};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #264d6d;
  height: 50px;
  
  &:focus-visible {
    border: 1px solid #006fc9;
  }
`;
Item.displayName = 'Item';
