import React from 'react';
import { useSelector } from 'react-redux';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import WorkspaceContext from 'Screens/Contextualization/Workspaces';
import Workspace from 'Screens/Workspaces';

const WorkspacesWrapper = ({ params }) => {
  const showLegacyUI = useSelector(selectShowLegacyUI);

  return (
    <>
      {
        showLegacyUI
          ? <Workspace />
          : <WorkspaceContext params={ params } />
      }
    </>
  );
};

export default WorkspacesWrapper;
