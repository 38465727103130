import React from 'react';
import ActionBar from './components/ActionBar';
import { HostData } from './components/HostData';
import Description from './components/Description';
import Resolution from './components/Resolution';
import References from './components/References';
import Policies from './components/Policies';
import ImpactsTab from './components/ImpactsTab';
import Owner from './components/Owner';
import ExternalId from './components/ExternalId';
import Tags from './components/Tags';
import RiskAndCVE from './components/RiskAndCVE';
import ToggleButtons from 'Screens/Contextualization/Manage/components/ToggleButtons';
import { Wrapper, Fields } from './styled';

const GeneralTab = () => (
  <Wrapper>
    <ActionBar />
    <HostData />
    <ToggleButtons id="general" />
    <Fields>
      <RiskAndCVE />
      <Description />
      <Resolution />
      <Tags />
      <References />
      <ImpactsTab />
      <Policies />
      <ExternalId />
      <Owner />
    </Fields>
  </Wrapper>
);

export default GeneralTab;
