import React from 'react';
import CustomButton from 'Common/Components/CustomButton';
import { ListViewIcon, Wrapper } from 'Screens/Workspaces/components/WSActionBar/components/RightGroup/ListViewButton/styled';
import { GridViewIcon } from 'Screens/Workspaces/components/WSActionBar/components/RightGroup/GridViewButton/styled';

export const GridViewButton = ({ showGridView, onToggleView }) => {
  // The button will only render if showGridView is false
  return !showGridView && (
    <Wrapper>
      <CustomButton
        icon={ <GridViewIcon /> }
        title="Grid View"
        text="Grid View"
        onClick={ onToggleView } // Call the onToggleView function passed as a prop
      />
    </Wrapper>
  );
};

export const ListViewButton = ({ showGridView, onToggleView }) => {
  // The button will only render if showGridView is true
  return showGridView && (
    <Wrapper>
      <CustomButton
        icon={ <ListViewIcon /> }
        title="List View"
        text="List View"
        onClick={ onToggleView } // Call the onToggleView function passed as a prop
      />
    </Wrapper>
  );
};
