import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsFetching } from 'store/Agents/selectors';
import isEmpty from 'lodash/isEmpty';
import Wrapper from './styled';
import EmptyAgents from './components/EmptyAgents';
import UserAgents from './components/UserAgents';

const Grid = ({ agents }) => {
  const isFetching = useSelector(selectIsFetching);
  const noAgents = isEmpty(agents) && !isFetching;

  if (noAgents) {
    return (
      <Wrapper>
        <EmptyAgents />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <UserAgents agents={ agents } />
    </Wrapper>
  );
};

export default Grid;
