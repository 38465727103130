import React from 'react';
import { Accept, Cancel } from '../Button/styled';
import {
  Wrapper, Title, Footer, WarningIcon
} from './styled';

const Warning = ({
  title, children, onAccept, onClose
}) => (
  <Wrapper>
    <Title>
      <WarningIcon />
      {title}
    </Title>
    { children }
    <Footer>
      { onClose ? <Cancel onClick={ onClose }>Close</Cancel> : null }
      { onAccept ? <Accept onClick={ onAccept }>Accept</Accept> : null }
    </Footer>
  </Wrapper>
);

export default Warning;
