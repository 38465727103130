import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Title, Text, Br } from './styled';

export const sidebarMenuTourConfig = () => [
  {
    selector: 'first-tour-step',
    content: ''
  },
  {
    selector: '[data-tour="Faraday Main Screen"]',
    position: 'right',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.sidebar.main.title" /> } />
        <Text children={ <FormattedMessage id="tour.sidebar.main.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Vulnerability Management"]',
    position: 'right',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.sidebar.manage.title" /> } />
        <Text children={ <FormattedMessage id="tour.sidebar.manage.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Knowledge Base"]',
    position: 'right',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.sidebar.knowledgeBase.title" /> } />
        <Text children={ <FormattedMessage id="tour.sidebar.knowledgeBase.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  }
];

export const dashboardTourConfig = () => [
  {
    selector: 'first-tour-step',
    content: ''
  },
  {
    selector: '[data-tour="Workspace Selector"]',
    position: 'bottom',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.dashboard.wsSelector.title" /> } />
        <Text children={ <FormattedMessage id="tour.dashboard.wsSelector.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Dashboard"]',
    position: 'right',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.dashboard.dashboardTab.title" /> } />
        <Text children={ <FormattedMessage id="tour.dashboard.dashboardTab.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Assets"]',
    position: 'right',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.dashboard.assetsTab.title" /> } />
        <Text children={ <FormattedMessage id="tour.dashboard.assetsTab.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Vulnerabilities"]',
    position: 'right',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.dashboard.vulnsTab.title" /> } />
        <Text children={ <FormattedMessage id="tour.dashboard.vulnsTab.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Services"]',
    position: 'right',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.dashboard.servicesTab.title" /> } />
        <Text children={ <FormattedMessage id="tour.dashboard.servicesTab.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  }
];

export const assetVulnerTourConfig = () => [
  {
    selector: 'first-tour-step',
    content: ''
  },
  {
    selector: '[data-tour="Asset Selector"]',
    position: 'bottom',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.dashboard.wsSelector.title" /> } />
        <Text children={ <FormattedMessage id="tour.dashboard.wsSelector.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Add Vulnerability in Asset"]',
    position: 'bottom',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.assetsVulns.addVuln.title" /> } />
        <Text children={ <FormattedMessage id="tour.assetsVulns.addVuln.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  }
];

export const vulnsTourConfig = () => [
  {
    selector: 'first-tour-step',
    content: ''
  },
  {
    selector: '[data-tour="Toolbar Vulnerabilities"]',
    position: 'bottom',
    style: { maxWidth: '375px', height: '244px', padding: '34px', width: '375px' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.vulns.toolbar.title" /> } />
        <Text children={ <FormattedMessage id="tour.vulns.toolbar.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  }
];
