import React from 'react';
import { useSelector } from 'react-redux';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import Manage from 'Screens/Manage';
import ManageContext from 'Screens/Contextualization/Manage';

const ManageWrapper = ({ params }) => {
  const showLegacyUI = useSelector(selectShowLegacyUI);

  return (
    <>
      {
        showLegacyUI
          ? <Manage params={ params } />
          : <ManageContext params={ params } />
      }
    </>
  );
};

export default ManageWrapper;
