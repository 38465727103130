import styled from 'styled-components';
import { ReactComponent as EvidenceSVG } from 'Images/icon-evidence.svg';

const EvidenceIcon = styled(EvidenceSVG)`
  path {
    fill: #1c4566;
    stroke: #1c4566;
  }
`;
EvidenceIcon.displayName = 'EvidenceIcon';

export default EvidenceIcon;
