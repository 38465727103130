import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { deleteVulnsSelected, hideModalDelete } from 'store/Contextualization/AssetDetail/actions';
import { selectSelected, selectShowDeleteConfirmation, selectIsFetching, selectSelectAllVulns, selectCount } from 'store/Contextualization/AssetDetail/selectors';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const showDeleteConfirmation = useSelector((state) => selectShowDeleteConfirmation('vulns', state));
  const isFetching = useSelector((state) => selectIsFetching('vulns', state));
  const selectAll = useSelector(selectSelectAllVulns);
  const vulnsCount = useSelector((state) => selectCount('vulns', state));
  const count = selectAll ? vulnsCount : vulnsSelected.length;

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ () => dispatch(deleteVulnsSelected()) }
      handleClose={ () => dispatch(hideModalDelete('vulns')) }
      entity="vulnerability"
      count={ count }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
