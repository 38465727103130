import React from 'react';
import { useSelector } from 'react-redux';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import Host from 'Screens/Host';
import HostContext from 'Screens/Contextualization/Host';

const HostWrapper = ({ params, ...props }) => {
  const showLegacyUI = useSelector(selectShowLegacyUI);

  return (
    <>
      {
        showLegacyUI
          ? <Host params={ params } { ...props } />
          : <HostContext params={ params } { ...props } />
      }
    </>
  );
};

export default HostWrapper;
