import React from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper, IconButton, RefreshIcon } from './styled';

const TopButtons = ({ serviceId, loadServiceDetail }) => {
  const dispatch = useDispatch();
  const handleClickRefresh = () => {
    dispatch(loadServiceDetail(serviceId));
  };

  return (
    <Wrapper>
      <IconButton title="Refresh" onClick={ handleClickRefresh }>
        <RefreshIcon />
      </IconButton>
    </Wrapper>
  );
};

export default TopButtons;
