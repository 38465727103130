import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 50px 50px 50px;
  border-radius: 4px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: ${colors.white};
`;
Wrapper.displayName = 'Wrapper';

export const AgentsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)); /* Assuming 320px is the minimum width for a column */
  gap: 30px; /* This sets the space between the grid items */
  
  @media (min-width: 0px) and (max-width: 1099px) {
    grid-template-columns: 1fr; /* 1 column for small screens */
  }

  @media (min-width: 1100px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium and large screens */
  }
`;
AgentsWrapper.displayName = 'AgentsWrapper';

export const Title = styled.div`
  user-select: none;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #1c2243;
`;
Title.displayName = 'Title';
