import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ActiveImage } from 'Images/unarchive.svg';
import { ReactComponent as ArchivedImage } from 'Images/archive.svg';

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
Wrapper.displayName = 'Wrapper';

export const ActiveIcon = styled(ActiveImage)`
`;
ActiveIcon.displayName = 'ActiveIcon';

export const ArchivedIcon = styled(ArchivedImage)`
`;
ArchivedIcon.displayName = 'ArchivedIcon';

export const Text = styled.span`
  font-size: 13px;
  color: ${colors.grey19};
  margin-left: 9px;
`;
Text.displayName = 'Text';
