import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import TagIcon from './styled';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE_ACTION_BAR } from 'store/modals/modals';
import FFTags from 'Images/ff_tags.png';

const TagButton = ({ setFF, hasItems }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    if (hasItems) {
      setFF(FFTags, 'upgradeLicense.sidebar.tags.description');
      dispatch(openModal(MODAL_UPGRADE_LICENSE_ACTION_BAR));
    }
  };

  return <IconButton icon={ <TagIcon /> } title="Tags" onClick={ onClick } disabled={ !hasItems } />;
};

export default TagButton;
