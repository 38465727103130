import get from 'lodash/get';
import defaultFields from 'store/Workspace/fields';

export const selectWorkspaceActivity = (state) => get(state, 'preferences.workspaceActivity', []);
export const selectAutoSelectWs = (state) => get(state, 'preferences.autoSelectWs', false);

export const selectFields = (state) => {
  const fields = get(state, 'preferences.workspaces_table', defaultFields);
  const newFields = {};
  Object.keys(defaultFields).forEach((key) => {
    const defaultField = defaultFields[key];
    const field = get(fields, key, defaultField);
    newFields[key] = {
      ...defaultField,
      ...field
    };
  });
  return newFields;
};
