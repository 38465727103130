import React from 'react';
import CustomFieldsTab from '../CustomFieldsTab';
import TechnicalTab from '../TechnicalTab';
import GeneralTab from '../GeneralTab';

const TABS = [
  {
    id: 'general',
    intlId: 'kb.detail.tab.general',
    component: <GeneralTab />
  },
  {
    id: 'technicalDetails',
    intlId: 'kb.detail.tab.technicalDetails',
    component: <TechnicalTab />
  },
  {
    id: 'customFields',
    intlId: 'kb.detail.tab.customAttributes',
    component: <CustomFieldsTab />
  }
];

export default TABS;
