import React, { useEffect, useState } from 'react';
import { Wrapper, ButtonWrapper, TopWrapper } from './styled';
import ActionBar from './components/ActionBar';
import Grid from './components/Grid';
import Table from './components/Table';
import Tabs from './components/Tabs';
import { getUserAgents } from 'store/Agents/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SelectShowCloudAgents, selectCloudAgents, selectUserAgents } from 'store/Agents/selectors';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { MODAL_UPGRADE_LICENSE_CLOUD_AGENTS } from 'store/modals/modals';
import ReduxModal from 'Common/Components/ReduxModal';
import FFCloudAgents from 'Images/ff_cloud_agents.png';
import { openModal } from 'store/modals/actions';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import { GridViewButton, ListViewButton } from './components/ActionBar/components/ViewButtons';
import LegacyActionBar from './components/LegacyActionBar';

const Agents = () => {
  const dispatch = useDispatch();
  const [showGridView, setShowGridView] = useState(false);
  const showCloudAgents = useSelector(SelectShowCloudAgents);
  const userAgents = useSelector(selectUserAgents);
  const cloudAgents = useSelector(selectCloudAgents);
  const showLegacyUI = useSelector(selectShowLegacyUI);
  const showNewUI = !showLegacyUI;

  useEffect(() => {
    dispatch(getUserAgents());
  }, [dispatch]);

  const toggleView = () => {
    setShowGridView(!showGridView);
  };
  const toggleAgents = () => dispatch(openModal(MODAL_UPGRADE_LICENSE_CLOUD_AGENTS));

  return (
    <Wrapper>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE_CLOUD_AGENTS }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_CLOUD_AGENTS } image={ FFCloudAgents } descriptionIntlId={ 'upgradeLicense.sidebar.cloudAgents.description' } />
      </ReduxModal>
      { showLegacyUI && <LegacyActionBar onToggleView={ toggleView } showGridView={ showGridView } showCloudAgents={ showCloudAgents } /> }
      { showNewUI
        ? <TopWrapper>
            <Tabs onToggleAgents={ toggleAgents } />
            <ButtonWrapper>
            {showGridView
              ? (
              <ListViewButton showGridView={ showGridView } onToggleView={ toggleView } />
                )
              : (
              <GridViewButton showGridView={ showGridView } onToggleView={ toggleView } />
                )}
            </ButtonWrapper>
          </TopWrapper>
        : <Tabs onToggleAgents={ toggleAgents } />
      }
      { showNewUI && <ActionBar showCloudAgents={ showCloudAgents } /> }
      {
        showGridView
          ? <Grid agents={ showCloudAgents ? cloudAgents : userAgents } />
          : <Table agents={ showCloudAgents ? cloudAgents : userAgents } />
      }
    </Wrapper>
  );
};

export default Agents;
