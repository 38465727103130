/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { getCustomAttributes } from 'store/CustomAttributes/actions';
import {
  resetErrorValue,
  resetState,
  showManageLeftFilters,
  hideVulnerabilityDetail,
  loadVulnerabilityDetail
} from 'store/Manage/actions';
import {
  clearAdvancedFilter, getCustomFilters, resetFilters
} from 'store/Filters/actions';
import { getSelectedEntity } from 'store/Filters/selectors';
import { selectShowHostLeftFilters } from 'store/Host/selectors';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { selectError, selectErrorMessage, selectShowDetail } from 'store/Manage/selectors';
import { Wrapper, TableContainer } from './styled';
import ManageTable from './components/ManageTable';
import VulnerabilityDetail from './components/VulnerabilityDetail';
import CustomActionBar from './components/CustomActionBar';
import FilterSideBar from './components/FilterSideBar';
import TemplateModal from './components/ManageTable/components/ContextMenu/components/TemplateModal';
import 'Screens/Contextualization/Manage/styles/manageStyles.scss';
import { removeCurrentHost } from 'store/Host/actions';
import { VulnsTour } from 'Common/Components/Contextualization/Tour';
import selectModal from 'store/modals/selectors';
import { MODAL_MOVE_VULN_HOST, MODAL_CANT_CREATE } from 'store/modals/modals';
import { MoveVulnModal } from './components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/HostData';
import { getHostsCountInWs, getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import WarningModal from 'Screens/Contextualization/ManageEditCreate/components/WarningModal';
import { closeModal } from 'store/modals/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

const ManageContext = ({ params }) => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const selectedEntity = useSelector(getSelectedEntity);
  const showHostFilters = useSelector(selectShowHostLeftFilters);
  const showDetail = useSelector(selectShowDetail);
  const showMoveVulnModal = useSelector((state) => selectModal(state, MODAL_MOVE_VULN_HOST));
  const showModalCantCreate = useSelector((state) => selectModal(state, MODAL_CANT_CREATE));

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadVulnerabilityDetail(id));
    else dispatch(hideVulnerabilityDetail());
  }, [params]);

  useEffect(() => {
    dispatch(getCustomAttributes());
    dispatch(getCustomFilters());
    if (showHostFilters) dispatch(showManageLeftFilters(true));
    else dispatch(showManageLeftFilters(false));
  }, [showHostFilters, selectedEntity, dispatch]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => {
    dispatch(getVulnsCountInWs());
    dispatch(getHostsCountInWs());
  }, [dispatch, workspaceSelected]);

  useEffect(() => () => {
    dispatch(resetState());
    dispatch(resetFilters('vulns'));
    dispatch(clearAdvancedFilter('vulns'));
    dispatch(closeModal(MODAL_CANT_CREATE));
  }, [dispatch]);

  useEffect(() => {
    dispatch(removeCurrentHost());
  }, [dispatch]);

  return (
    <Wrapper>
      <CustomActionBar />
      <TableContainer>
        <ManageTable />
        <FilterSideBar entity="vulns" />
        { showDetail && <VulnerabilityDetail /> }
        <TemplateModal />
      </TableContainer>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetErrorValue()) } /> : null }
      <VulnsTour />
      {showMoveVulnModal && <MoveVulnModal />}
      { showModalCantCreate && <WarningModal entity="Vulnerability" /> }
    </Wrapper>
  );
};

ManageContext.propTypes = {
  params: PropTypes.object.isRequired
};

export default ManageContext;
