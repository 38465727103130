import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as SeveritySVG } from 'Images/icon_severity.svg';
import SeverityComponent from 'Common/Components/SeverityIcon';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const SeverityIcon = styled(SeveritySVG)`
  width: 18px;
  height: 19px;
  margin-bottom: 2px;
  & > g {
    fill: #1c4566;
  }
`;
SeverityIcon.displayName = 'SeverityIcon';

export const Dropdown = styled.div`
  position: absolute;
  width: 200px;
  box-shadow: 2px 2px 10px 0 #d5dee6;
  background-color: ${colors.white};
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  z-index: 3;
`;
Dropdown.displayName = 'Dropdown';

export const SeverityLetter = styled(SeverityComponent)`
  margin: auto 13px auto 0px;
  & > path {
    fill: ${colors.blueGrey};
  }
`;
SeverityLetter.displayName = 'SeverityLetter';
