import get from 'lodash/get';
import defaultFields from 'store/KnowledgeBase/fields';

// eslint-disable-next-line import/prefer-default-export
export const selectFields = (state) => {
  const fields = get(state, 'preferences.kb_table', defaultFields);
  const newFields = {};
  Object.keys(defaultFields).forEach((key) => {
    const defaultField = defaultFields[key];
    const field = get(fields, key, defaultField);
    newFields[key] = {
      ...defaultField,
      ...field
    };
  });
  return newFields;
};
