import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTemplatesSelected } from 'store/KnowledgeBase/selectors';
import { selectRow } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import Checkbox from 'Common/Components/Checkbox';

export const CheckColum = ({ template }) => { // eslint-disable-line import/prefer-default-export
  const templatesSelected = useSelector((state) => selectTemplatesSelected(state));
  const dispatch = useDispatch();
  const isSelected = templatesSelected.find((templateSelected) => templateSelected._id === template._id);
  const handleClick = (e) => dispatch(selectRow(e, template));

  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
