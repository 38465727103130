import React from 'react';
import { useDispatch } from 'react-redux';
import Title from './Title';
import CopyLink from './CopyLink';
import CopyApiLink from './CopyApiLink';
import { Wrapper, CloseIconWrapper, CloseIcon } from './styled';
import { closeDetail } from 'Screens/Contextualization/Workspaces/actions/Actions';

const Header = () => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Title />
      <CopyLink />
      <CopyApiLink />
      <CloseIconWrapper onClick={ () => dispatch(closeDetail()) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
