import React from 'react';
import { Wrapper, Item, Text } from './styled';

const List = ({ data }) => {
  const isOdd = (n) => n % 2 !== 0;
  if (!data) return null;
  return (
    <Wrapper>
      { data.map((element, i) =>
        <Item isOdd={ isOdd(i) } key={ `item_${element}_${i}` }>
          <Text>{ element }</Text>
        </Item>
      )}
    </Wrapper>
  );
};

export default List;
