import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import TABS from 'Screens/Contextualization/Host/components/HostVulnerabilities/VulnerabilityDetail/components/Tabs/components/TabSelector/tabs';
import { selectAdvancedFilter } from 'store/Filters/selectors';

export const selectHostDetail = (state) => get(state, 'hostDetail.host', null);
export const selectHostDetailId = (state) => get(state, 'hostDetail.host._id', null);

export const selectVulns = (state) => get(state, 'hostDetail.vulns.data', []);
export const selectFields = (state) => get(state, 'hostDetail.vulns.fields', []);

export const selectShowHostDetail = (state) => {
  const show = get(state, 'hostDetail.host', null);
  return !!show;
};

export const selectSelectedService = (state) => get(state, 'hostDetail.createOrEditService.selectedService', null);

export const selectIsFetchingServices = (state) => get(state, 'hostDetail.services.isFetching', false);
export const selectVulnsCount = (state) => get(state, 'hostDetail.vulns.count', 0);

export const selectIsFetching = (entity, state) => get(state, `hostDetail.${entity}.isFetching`, false);
export const selectServices = (state) => get(state, 'hostDetail.services.data', []);
export const selectServicesFields = (state) => get(state, 'hostDetail.services.fields', []);
export const selectSelected = (entity, state) => get(state, `hostDetail.${entity}.selected`, []);
export const selectCount = (entity, state) => get(state, `hostDetail.${entity}.count`, null);
export const selectPage = (entity, state) => get(state, `hostDetail.${entity}.page`, 0);
export const selectServiceRowsPerPage = (state) => get(state, 'hostDetail.services.rowsPerPage', 0);
export const selectLastSelected = (entity, state) => get(state, `hostDetail.${entity}.selectLastSelectedService`, -1);
export const selectError = (entity, state) => get(state, `hostDetail.${entity}.error`, false);
export const selectErrorMessage = (entity, state) => get(state, `hostDetail.${entity}.errorMessage`, '');
export const selectShowDetail = (entity, state) => get(state, `hostDetail.${entity}.showDetail`, false);
export const selectDetail = (entity, state) => get(state, `hostDetail.${entity}.detail`, null);
export const selectShowDeleteConfirmation = (entity, state) => get(state, `hostDetail.${entity}.showDeleteConfirmation`, false);
export const selectedForWorking = (entity, state) => get(state, `hostDetail.${entity}.selectedForWorking[0]`, []);
export const selectServiceId = (state) => get(state, 'hostDetail.services.detail._id', 0);
export const selectServiceOwned = (state) => get(state, 'hostDetail.services.detail.owned', false);
export const selectServiceDescription = (state) => get(state, 'hostDetail.services.detail.description', '');
export const selectVulnDetailId = (state) => get(state, 'hostDetail.vulns.detail._id', 0);

export const allServicesAreSelected = (state) => {
  const services = selectServices(state);
  const serviceList = selectSelected('services', state);
  return services.every((testService) => serviceList.some((service) => service._id === testService._id));
};
export const someServiceIsSelected = (state) => {
  return selectSelected('services', state).length > 0;
};

export const selectVulnSelectedTabId = (state) => get(state, 'hostDetail.vulns.selectedTab', 'general');
export const selectVulnSelectedTab = (state) => {
  const selectedTabId = selectVulnSelectedTabId(state);
  const defaultTab = TABS.find((tab) => tab.id === 'general');
  const selectedTab = TABS.find((tab) => tab.id === selectedTabId) || defaultTab;
  return selectedTab;
};

export const allVulnsAreSelected = (state) => {
  const vulns = selectVulns(state);
  const vulnsSelected = selectSelected('vulns', state);
  return vulns.every((testVuln) => vulnsSelected.some((vuln) => vuln._id === testVuln._id)) && vulnsSelected.length > 0;
};

export const someVulnIsSelected = (state) => {
  return selectSelected('vulns', state).length > 0;
};

export const selectHostDetailOs = (state) => get(state, 'hostDetail.host.os', '');
export const selectHostDetailMac = (state) => get(state, 'hostDetail.host.mac', '');
export const selectHostDetailDescription = (state) => get(state, 'hostDetail.host.description', '');
export const selectHostDetailHostnames = (state) => get(state, 'hostDetail.host.hostnames', null);
export const selectHostDetailOwned = (state) => get(state, 'hostDetail.host.owned', false);
export const selectHostDetailImportance = (state) => get(state, 'hostDetail.host.importance', 0);
export const selectHostDetailCreateDate = (state) => get(state, 'hostDetail.host.metadata.create_time', '');
export const selectHostDetailIp = (state) => get(state, 'hostDetail.host.ip', '');

export const selectToolsUsers = (state) => {
  const toolsData = get(state, 'hostDetail.tools.data', '');
  const users = toolsData.flatMap((tool) => tool.user);
  return users;
};

export const selectToolsParameters = (state) => {
  const toolsData = get(state, 'hostDetail.tools.data', '');
  const params = toolsData.flatMap((tool) => tool.params);
  return params;
};

export const selectToolsCommands = (state) => {
  const toolsData = get(state, 'hostDetail.tools.data', '');
  const commands = toolsData.flatMap((tool) => tool.command);
  return commands;
};

export const selectReadonly = (state) => {
  const readonly = get(state, 'faraday.readonly', false);
  return readonly;
};

export const selectVulnsQueryParam = (assetIp, state) => {
  const entityFilters = get(state, 'filters.vulnsAssets', null);

  const defaultFilter = {
    name: 'target',
    op: '==',
    val: assetIp
  };

  if (entityFilters) {
    const {
      page, rowsPerPage, order_by, filters
    } = entityFilters;
    const offset = (page - 1) * rowsPerPage;

    let parsedFilter = { offset, limit: rowsPerPage };
    const isOrdering = !isEmpty(order_by);
    const isFiltering = !isEmpty(filters);

    if (isOrdering) parsedFilter = Object.assign(parsedFilter, { order_by }, { filters: defaultFilter });
    if (isFiltering) {
      if (filters[0].and) {
        const filtersObj = filters[0].and.map((item) => item);
        const newFilters = [...filtersObj, defaultFilter];
        parsedFilter = Object.assign(parsedFilter, { filters: [{ and: newFilters }] });
      } else {
        parsedFilter = Object.assign(parsedFilter, { filters: [{ and: [defaultFilter, filters[0]] }] });
      }
    }
    return parsedFilter;
  }

  return [];
};

export const selectVulnsAdvancedFilterQueryParam = (assetIp, state) => {
  const entityFilters = get(state, 'filters.vulnsAssets', null);
  const {
    page, rowsPerPage, order_by
  } = entityFilters;
  const advancedFilter = selectAdvancedFilter(state, 'vulnsAssets');
  const defaultFilter = `{"name":"target","op":"==","val":"${assetIp}"}`;
  const orderBy = JSON.stringify(order_by);
  const offset = (page - 1) * rowsPerPage;

  let queryToParse;
  if (advancedFilter) queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${defaultFilter}, ${advancedFilter}]}`;
  else queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${defaultFilter}]}`;

  const parsedQuery = JSON.parse(queryToParse);
  return parsedQuery;
};

export const selectShowVulnsSidebarFilters = (state) => get(state, 'hostDetail.vulns.showFiltersSidebar', false);
export const selectCurrentAsset = (state) => get(state, 'hostDetail.hostForWorking', {});

export const selectModalBulkUpdateField = (state) => get(state, 'hostDetail.vulns.bulkUpdateField', '');
export const selectModalBulkUpdateValue = (state) => get(state, 'hostDetail.vulns.bulkUpdateValue', '');

export const selectSelectAllVulns = (state) => get(state, 'hostDetail.vulns.selectAll', false);

export const selectUnselectedRowFromSelectAll = (state, entity) => get(state, `hostDetail.${entity}.unselectedRowFromSelectAll`, false);

export const selectShowContextMenu = (state) => get(state, 'hostDetail.vulns.showContextMenu', false);
export const selectContextMenuXPos = (state) => get(state, 'hostDetail.vulns.contextMenuXPos', 0);
export const selectContextMenuYPos = (state) => get(state, 'hostDetail.vulns.contextMenuYPos', 0);
