/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import DRP from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import StringField from 'Common/Components/StringField';
import {
  CalendarWrapper, StringFieldContainer, Wrapper, CleanButton, CloseButton
} from './styled';

const moment = extendMoment(originalMoment);

class DateRangePicker extends React.Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      isOpen: false,
      value: null
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onSelect (value) {
    this.setState({ value });
    // if (value.end) this.onToggle();
  }

  onToggle () {
    const { isOpen, value } = this.state;
    const { disabled } = this.props;
    if (isOpen && value) this.props.onClose(value.start, value.end);

    if (!disabled) this.setState({ isOpen: !isOpen });
  }

  handleClickOutside (event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.state.isOpen) this.onToggle();
    }
  }

  render () {
    const today = moment();
    const {
      width, height, locale, start, end, id, hasMargin
    } = this.props;

    const value = this.state.value
      // eslint-disable-next-line no-negated-condition
      ? (this.state.value.start.format('ddd MMM D YYYY') !== this.state.value.end.format('ddd MMM D YYYY') ? `${this.state.value.start.format('ddd MMM D YYYY')} - ${this.state.value.end.format('ddd MMM D YYYY')}` : `${this.state.value.start.format('ddd MMM D YYYY')}`)
      : (start && end ? `${moment(start).format('ddd MMM D YYYY')} - ${moment(end).format('ddd MMM D YYYY')}` : (start ? `${moment(start).format('ddd MMM D YYYY')}` : (end ? `${moment(end).format('ddd MMM D YYYY')}` : `${today.clone().format('ddd MMM D YYYY')}`)));

    return (
      <Wrapper ref={ this.wrapperRef }>
        <StringFieldContainer onClick={ this.onToggle } highlight={ (this.state.value && this.state.value.length > 0) || (start && end) } hasMargin={ hasMargin } >
          <StringField width={ width } height={ height } placeholder={ value } onChange={ () => {} } readOnly id={ id } />
        </StringFieldContainer>
        {this.state.isOpen && (
          <CalendarWrapper width={ 170 }>
            <DRP
              value={ this.state.value ? this.state.value : (start && end ? moment.range(moment(start).clone(), moment(end).clone()) : moment.range(today.clone(), today.clone())) }
              onSelect={ this.onSelect }
              singleDateRange
              locale={ locale }
              helpMessage=""
            />
            <CleanButton onClick={ () => { this.setState({ value: null }); this.props.cleanDates(); } }>Clean</CleanButton>
            <CloseButton onClick={ this.onToggle }>Save</CloseButton>
          </CalendarWrapper>
        )}
      </Wrapper>
    );
  }
}

export default DateRangePicker;
