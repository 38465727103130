import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  height: ${(props) => !props.emptyAgents && '100%'};
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
`;
TopWrapper.displayName = 'TopWrapper';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  height: 37px;
`;
ButtonWrapper.displayName = 'ButtonWrapper';
