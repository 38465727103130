import React from 'react';
import get from 'lodash/get';
import Text from './styled';
import formatNumber from 'Common/Functions/FormatNumber';
import Skeleton from 'Common/Components/Skeleton';

const GenericCell = ({
  rowInfo, capitalize, attrib, showSkeleton
}) => {
  const label = get(rowInfo, attrib, '');
  const labelFormatted = (attrib === 'vulns' || attrib === 'credentials') ? formatNumber(label) : label;

  if (showSkeleton) return <Skeleton />;
  return (
    <Text capitalize={ capitalize }>
      { labelFormatted }
    </Text>
  );
};

export default GenericCell;
