import React, { useEffect } from 'react';
import {
  Col, Row
} from 'Common/Components/Grid/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTopServices
} from 'Screens/Contextualization/Dashboard/actions/Actions';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter } from 'store/Filters/actions';
import { redirect } from 'store/Router/actions';
import {
  Description, StyledCol, Text, Title, Empty, Details
} from './styled';
import formatNumber from 'Common/Functions/FormatNumber';

const TopServices = () => {
  const data = useSelector((state) => get(state, 'dashboard.tools[1].data', []));
  const workspaceSelected = useSelector(selectCurrentWorkspace);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopServices());
  }, [dispatch, workspaceSelected]);

  let content = <div />;

  const onServiceSelection = (serviceName) => {
    dispatch(setFilter('assets', DASHBOARD_FILTERS.assetsByServiceName(serviceName)));
    dispatch(redirect(`/host/${workspaceSelected}`));
  };

  if (get(data, 'total_count', 0) > 0) {
    content = (
      <div>
        <Row centered>
          <Col textAlign="right"><Description>Services</Description></Col>
        </Row>
        <StyledCol>
          {
              data && data.groups
                ? data.groups.sort((a, b) => b.count - a.count)
                  .slice(0, 6).map((service, index) => (
                  <Row centered key={ `dashboard_top_services_${index}` }>
                    <Col width="70%" onClick={ () => onServiceSelection(service.name) }><Text bold>{service.name.toUpperCase()}</Text></Col>
                    <Col width="30%" textAlign="right"><Details>{formatNumber(service.count)}</Details></Col>
                  </Row>
                  ))
                : null
            }
        </StyledCol>
      </div>
    );
  } else {
    content = <Empty>No top services has been found yet.</Empty>;
  }

  return (
    <Row>
      <Col width="100%">
        <Row>
          <Col><Title>Top Services</Title></Col>
        </Row>
        { content }
      </Col>
    </Row>
  );
};

export default TopServices;
