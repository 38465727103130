/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ReactComponent as TriggerButton } from 'Images/icon-action-bar-trigger.svg';
import { ReactComponent as DisabledTriggerButton } from 'Images/icon-action-bar-trigger-disabled.svg';
import { openModal } from 'store/modals/actions';
import { getAgentTools } from 'store/Agents/actions';
import { MODAL_RUN_USER_AGENT } from 'store/modals/modals';
import { useDispatch } from 'react-redux';
import Wrapper from './styled';

const RunIcon = ({ agent }) => {
  const dispatch = useDispatch();
  const id = get(agent, 'id', null);
  const isOnline = get(agent, 'is_online', true);
  // const isOnline = true;

  const onTriggerAgent = () => {
    if (id) {
      dispatch(getAgentTools(id));
      dispatch(openModal(MODAL_RUN_USER_AGENT));
    }
  };

  return (
    <Wrapper id="modal-user-agent-run-btn" enabled={ isOnline } title="Run agent" onClick={ onTriggerAgent }>
      { isOnline ? <TriggerButton /> : <DisabledTriggerButton /> }
    </Wrapper>
  );
};

RunIcon.propTypes = {
  agent: PropTypes.object.isRequired
};

export default RunIcon;
