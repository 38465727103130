import React from 'react';
import SeverityPicker from './components/SeverityPicker';
import DisplayConfirmed from './components/DisplayConfirmed';
import EaseOfResolutionPicker from './components/EaseOfResolutionPicker';
import StatusPicker from './components/StatusPicker';
import Wrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ActionBar/styled';

const ActionBar = () => (
  <Wrapper>
    <SeverityPicker />
    <DisplayConfirmed />
    <EaseOfResolutionPicker />
    <StatusPicker />
  </Wrapper>
);
export default ActionBar;
