import styled from 'styled-components';
import { ReactComponent as SaveTemplateSVG } from 'Images/save_template.svg';

const SaveTemplateIcon = styled(SaveTemplateSVG)`
  margin: 3px 0 0 2px;
  & > path {
    fill: #1c4566;
  }
`;
SaveTemplateIcon.displayName = 'SaveTemplateIcon';

export default SaveTemplateIcon;
