import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { updateVulnTemplate } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectVulnTemplateDetail } from 'store/KnowledgeBase/selectors';
import CustomList from 'Common/Components/CustomList';
import Wrapper from './styled';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectReadOnly } from 'store/Faraday/selectors';

const TAB = 'general';
const ID = 'policies';

const Policies = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vulnTemplate = useSelector(selectVulnTemplateDetail);
  const list = get(vulnTemplate, 'policyviolations', []);
  const id = get(vulnTemplate, '_id', 0);
  const isExpanded = useExpandable(TAB, ID, list);
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;

  const onAdd = (valueAdded) => {
    const newRefs = [...list, valueAdded];
    dispatch(updateVulnTemplate(id, { policyviolations: newRefs }));
  };

  const onRemove = (indexToRemove) => {
    const newPolicies = list.filter((pol, index) => index !== indexToRemove);
    dispatch(updateVulnTemplate(id, { policyviolations: newPolicies }));
  };

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="policies" title={ intl.formatMessage({ id: 'manage.detail.tab.general.policies' }) } tab={ TAB }>
      <Wrapper>
        <CustomList
          key="DETAIL_CREATION_MODAL"
          placeholder="Add a Policy Violation"
          addItem={ onAdd }
          removeItem={ onRemove }
          listData={ list }
          responsive
          canUpdate={ canUpdate }
        />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Policies;
