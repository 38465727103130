import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { unSelectAll, selectRow, redirectToWsDetail } from 'Screens/Contextualization/Workspaces/actions/Actions';
import IconButton from 'Common/Components/IconButton';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import Edit from './styled';
import ModalWarning from 'Common/Components/ModalWarning';

// eslint-disable-next-line import/prefer-default-export
export const WsEditButton = () => {
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'workspace' });

  const showEditionModal = (e) => {
    dispatch(unSelectAll());
    dispatch(selectRow(e, workspacesSelected[0]));
    dispatch(redirectToWsDetail(workspacesSelected[0].name));
  };

  const handleClick = (e) => {
    if (workspacesSelected.length === 1) showEditionModal(e);
    else setShowWarning(true);
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};
