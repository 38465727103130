import React from 'react';
import { Tab, TabWrapper } from './styled';

const Tabs = ({ onToggleAgents }) => {
  return (
    <TabWrapper>
      <Tab isActive>Agents</Tab>
      <Tab onClick={ () => onToggleAgents() } >Cloud Agents</Tab>
    </TabWrapper>
  );
};

export default Tabs;
