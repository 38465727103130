import React from 'react';
import { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modals/actions';
import { MODAL_AGENT_CREATE } from 'store/modals/modals';
import { Wrapper, Button } from './styled';

const CreateButton = ({ showSeparator }) => {
  const dispatch = useDispatch();
  return (
    <Wrapper showSeparator={ showSeparator }>
      <Button title="Create" onClick={ () => dispatch(openModal(MODAL_AGENT_CREATE)) }>
        <PlusIcon />
        Create Agent
      </Button>
    </Wrapper>
  );
};
export default CreateButton;
