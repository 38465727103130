import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Wrapper, Box, Logo, Subtitle, Title, VersionWrapper, Version, Toggle, Button, Label } from './styled';
import Checkbox from 'Common/Components/Checkbox';
import { redirectToVersion } from 'Screens/Login/actions/Actions';

const PickVersion = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showCurrentVersion, setCurrentVersion] = useState(false);
  const [showTour, setShowTour] = useState(true);

  const enter = () => {
    dispatch(redirectToVersion(showCurrentVersion, !showTour));
  };

  return (
    <Wrapper>
      <Box>
        <Logo />
        <Title>{intl.formatMessage({ id: 'pickVersion.title' })}</Title>
        <Subtitle>{intl.formatMessage({ id: 'pickVersion.subtitle' })}</Subtitle>
        <VersionWrapper>
          <Version>{intl.formatMessage({ id: 'pickVersion.current' })}</Version>
          <Toggle checked={ !showCurrentVersion } onChange={ () => setCurrentVersion(!showCurrentVersion) } />
          <Version>
            {intl.formatMessage({ id: 'pickVersion.new' })}
            <Label>{' (beta)'}</Label>
          </Version>
        </VersionWrapper>
        <Checkbox state={ showTour } onChange={ () => setShowTour(!showTour) } text={ intl.formatMessage({ id: 'pickVersion.tour' }) } />
        <Button onClick={ enter } label={ intl.formatMessage({ id: 'pickVersion.redirect' }) } />
      </Box>
    </Wrapper>

  );
};

export default PickVersion;
