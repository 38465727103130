import styled from 'styled-components';
import { Col } from 'Common/Components/Grid';
import colors from 'Styles/colors';

export const Wrapper = styled.div.attrs({
  id: 'feed_section_container'
})`
  padding: 14px 8px 22px 14px;
  height: 100%;
  overflow: auto;
  background-color: ${colors.white};
  ::-webkit-scrollbar {
    display: none;
  }
`;
Wrapper.displayName = 'Wrapper';

export const StyledCol = styled(Col)`
  & > *:not(:last-child) {
    margin-bottom: 14px;
  }
`;
StyledCol.displayName = 'StyledCol';
