/* eslint-disable import/prefer-default-export */
import React from 'react';
import get from 'lodash/get';
import Skeleton from 'Common/Components/Skeleton';
import { Wrapper } from './styled';

export const Risk = ({ vuln, showSkeleton }) => {
  const risk = get(vuln, 'risk', null);

  if (showSkeleton) return <Skeleton />;
  if (!risk) return null;
  return (
    <Wrapper color={ risk.severity } >
        {risk.score}
    </Wrapper>

  );
};
