import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StandardTextField from 'Common/Components/StandardTextField/index';
import BlueButton from 'Common/Components/BlueButton/index';
import OutlinedButton from 'Common/Components/OutlinedButton/index';
import { CUSTOM_ATTRIBUTE_TYPES } from 'Screens/Contextualization/Manage/components/CustomAttributesModal/constants';
import toString from 'lodash/toString';
import trim from 'lodash/trim';
import { Wrapper, Buttons, Content } from './styled';

const StringCustomField = ({
  field, vuln, onCancel, onSave, initialValue
}) => {
  const [value, setValue] = useState(trim(toString(initialValue)));
  const [editMode, setEditMode] = useState(!initialValue);

  const handleSave = () => {
    onSave(vuln, field.field_name, value);
    setEditMode(false);
    if (!value) onCancel();
  };

  const handleCancel = () => {
    setEditMode(false);
    if (initialValue) {
      setValue(initialValue);
    } else {
      onCancel();
    }
  };

  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      {editMode
        ? (
          <StandardTextField
            name="custom_field_name"
            value={ value }
            onChange={ setValue }
          />
          )
        : <Content onClick={ () => setEditMode(true) }>{initialValue}</Content> }

      {editMode &&
      (
      <Buttons>
        <OutlinedButton label="Cancel" onClick={ handleCancel } />
        <BlueButton label="Save" onClick={ handleSave } />
      </Buttons>
      )}
    </Wrapper>
  );
};

StringCustomField.defaultProps = {
  initialValue: ''
};

StringCustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([CUSTOM_ATTRIBUTE_TYPES.STRING])
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  vuln: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  initialValue: PropTypes.string
};

export default StringCustomField;
