import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ isDisabled }) => isDisabled && 'cursor: not-allowed'};
`;
Wrapper.displayName = 'Wrapper';

export const SeverityName = styled.span`
  margin-left: 7px;
`;
SeverityName.displayName = 'SeverityName';
