import styled from 'styled-components';
import { ReactComponent as TemplateSVG } from 'Images/template.svg';

export const TemplateIcon = styled(TemplateSVG)`
  & > g {
    fill: #1c4566;
  }
`;
TemplateIcon.displayName = 'TemplateIcon';

export default TemplateIcon;
