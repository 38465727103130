import styled from 'styled-components';

const Wrapper = styled.div`
  flex-direction: column;
  flex: 1 1 0px;
  width: 100%;
  overflow: auto;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
