import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import { selectTourFinished } from 'store/Preferences/Tour/selectors';
import { setSideBarOpen } from 'store/SideBar/actions';
import { NextBtn, PrevBtn } from './styled';
import { endTour } from 'store/Manage/actions';

const CommonTour = ({ isTourOpen, setTour, steps }) => {
  const dispatch = useDispatch();
  const tourFinished = useSelector(selectTourFinished);

  useEffect(() => {
    setTour(!tourFinished);
    if (!tourFinished) dispatch(setSideBarOpen(!tourFinished));
  }, [dispatch, tourFinished, setTour]);

  const enabledSteps = steps.filter(({ selector }) => {
    if (selector === '' || selector === 'first-tour-step') return true;
    return !!document.querySelector(selector);
  });

  return (
    <Tour
      onRequestClose={ () => setTour(false) }
      steps={ enabledSteps }
      isOpen={ isTourOpen }
      lastStepNextButton={ <NextBtn children="Done!" /> }
      nextButton={ <NextBtn children="Next tip >" /> }
      prevButton={ <PrevBtn onClick={ () => setTour(false) } children="Exit Tour" /> }
      onBeforeClose={ () => dispatch(endTour()) }
      showNavigation={ false }
      showNumber={ false }
      showCloseButton={ false }
      maskClassName='tourBackground'
      startAt={ 1 }
    />
  );
};

export default CommonTour;
