/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import { redirectToManageDetail, showContextMenu } from 'store/Contextualization/AssetDetail/actions';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import head from 'lodash/head';
import { EvidenceIcon } from './styled';

export const AddNewEvidence = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const selectedVulnID = get(head(vulnsSelected), '_id', null);
  const isBulk = vulnsSelected.length > 1;

  if (isBulk) return null;
  return (
    <MenuItem
      title={ intl.formatMessage({ id: 'manage.cm.addNewEvidence' }) }
      icon={ <EvidenceIcon /> }
      onClick={ () => {
        if (selectedVulnID) dispatch(redirectToManageDetail(selectedVulnID, 'evidence'));
        dispatch(showContextMenu(false));
      } }
    />
  );
};
