import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  selectTemplateEditCreate
} from 'store/KnowledgeBase/selectors';
import { setField } from 'Screens/Contextualization/KnowledgeBaseEditCreate/actions/Actions';

import Md from 'Common/Components/Md';
import { Wrapper, Row } from './styled';

const TechnicalDetails = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => ({ ...selectTemplateEditCreate(state) }));

  const saveData = (value) => {
    dispatch(setField('data', value));
  };

  return (
    <Wrapper>
      <Row className="ml-0">
        <Md title="Data" saveAction={ saveData } size="lg" value={ props.data } />
      </Row>
    </Wrapper>
  );
};

export default withRouter(TechnicalDetails);
