/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useIntl } from 'react-intl';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch } from 'react-redux';
import { showContextMenu, showHostModalDelete } from 'store/Host/actions';
import { DeleteIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';

export const Delete = () => {
  const dispatch = useDispatch();
  const title = useIntl().formatMessage({ id: 'manage.cm.delete' });

  const handleClick = () => {
    dispatch(showHostModalDelete());
    dispatch(showContextMenu(false));
  };

  return (
    <MenuItem
      title={ title }
      color="#ea3158"
      icon={ <DeleteIcon /> }
      onClick={ handleClick }
    />
  );
};
