/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import head from 'lodash/head';
import { selectHostsSelected } from 'store/Host/selectors';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { EditIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';
import { showBulkUpdateModal, showContextMenu } from 'store/Host/actions';
import { redirectToHostDetail, setHostForWorking } from 'store/Contextualization/AssetDetail/actions';

export const Edit = () => {
  const dispatch = useDispatch();
  const hostsSelected = useSelector(selectHostsSelected);
  const isBulk = hostsSelected.length > 1;
  const selectedHost = head(hostsSelected);
  const selectedHostID = get(selectedHost, '_id', null);
  const intl = useIntl();

  const goToDetail = (inputName) => {
    if (isBulk) dispatch(showBulkUpdateModal(inputName, ''));
    else if (selectedHostID) {
      dispatch(setHostForWorking(selectedHost));
      dispatch(redirectToHostDetail(selectedHostID));
    }
    dispatch(showContextMenu(false));
  };

  const onOsEdit = () => {
    dispatch(showBulkUpdateModal('os', isBulk ? '' : selectedHost.os));
    dispatch(showContextMenu(false));
  };

  const onMacAddressEdit = () => {
    dispatch(showBulkUpdateModal('mac', isBulk ? '' : selectedHost.mac));
    dispatch(showContextMenu(false));
  };

  const onHostnamesEdit = () => {
    dispatch(showBulkUpdateModal('hostnames', isBulk ? [] : selectedHost.hostnames));
    dispatch(showContextMenu(false));
  };

  return (
    <ContextSubMenu
      icon={ <EditIcon /> }
      title={ intl.formatMessage({ id: 'manage.cm.edit' }) }
    >
      <MenuItem title={ intl.formatMessage({ id: 'asset.cm.edit.item.hostnames' }) } onClick={ onHostnamesEdit } />
      <MenuItem title={ intl.formatMessage({ id: 'asset.cm.edit.item.description' }) } onClick={ () => goToDetail('description') } />
      <MenuItem title={ intl.formatMessage({ id: 'asset.cm.edit.item.os' }) } onClick={ onOsEdit } />
      <MenuItem title={ intl.formatMessage({ id: 'asset.cm.edit.item.macAddress' }) } onClick={ onMacAddressEdit } />
    </ContextSubMenu>
  );
};
