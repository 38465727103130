import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setVulnTemplateDetailTab } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectTemplateDetailSelectedTab } from 'store/KnowledgeBase/selectors';
import TABS from './tabs';
import { TabSelectorWrapper, TabButton } from './styled';

const TabsSelector = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const selectedTab = useSelector(selectTemplateDetailSelectedTab);

  const getLabel = (tab) => intl.formatMessage({ id: tab.intlId });
  const isActive = (tab) => tab.id === selectedTab.id;

  return (
    <TabSelectorWrapper>
      { TABS.map((tab) => (
        <TabButton
          active={ isActive(tab) }
          key={ tab.id }
          onClick={ () => dispatch(setVulnTemplateDetailTab(tab.id)) }
          children={ getLabel(tab) }
        />
      ))}
    </TabSelectorWrapper>
  );
};

export default TabsSelector;
