import React from 'react';
import { useSelector } from 'react-redux';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import HostVulnerabilities from 'Screens/Contextualization/Host/components/HostVulnerabilities';

const VulnsAssetWrapper = ({ params }) => {
  const showLegacyUI = useSelector(selectShowLegacyUI);

  if (showLegacyUI) return null;
  return <HostVulnerabilities params={ params } />;
};

export default VulnsAssetWrapper;
