import React from 'react';
import { Wrapper, Fields } from './styled';
import ActionBar from './components/ActionBar';
import Description from './components/Description';
import Resolution from './components/Resolution';
import References from './components/References';
import ImpactsTab from './components/ImpactsTab';
import ExternalId from './components/ExternalId';
import Policies from './components/Policies';

const GeneralTab = () => (
  <Wrapper>
    <Fields>
      <ActionBar />
      <Description />
      <Resolution />
      <References />
      <ImpactsTab />
      <ExternalId />
      <Policies />
    </Fields>
  </Wrapper>
);

export default GeneralTab;
