import React from 'react';
import { useSelector } from 'react-redux';
import { getRequestUrl } from 'services/api/connector';
import { useIntl } from 'react-intl';
import { selectWsDetailName } from 'store/Workspace/selectors';
import copyToClipboard from 'utils/clipboard';
import { CopyApiLinkWrapper, CopyApiLinkIcon } from './styled';

const CopyApiLink = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.detail.tab.copyApiLink' });
  const wsName = useSelector(selectWsDetailName);

  const copyApiLink = () => {
    if (wsName) {
      const wsPath = `ws/${wsName}`;
      const link = getRequestUrl(wsPath);
      copyToClipboard(link);
    }
  };

  return (
    <CopyApiLinkWrapper title={ title } onClick={ copyApiLink }>
      <CopyApiLinkIcon />
    </CopyApiLinkWrapper>
  );
};

export default CopyApiLink;
