import React from 'react';
import CommonConfirmationDialog from 'Common/Components/ConfirmationDialog';
import { useSelector, useDispatch } from 'react-redux';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import selectModal from 'store/modals/selectors';

const BulkUpdateConfirmationDialog = ({
  selected, count, isSelectAll, isFetching, bulkUpdateValue,
  bulkUpdateAction, title
}) => {
  const showModal = useSelector((state) => selectModal(state, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
  const dispatch = useDispatch();
  const itemsCount = isSelectAll ? count : selected.length;

  if (!showModal) return null;
  return (
    <CommonConfirmationDialog
      visible={ showModal }
      isFetching={ isFetching }
      title="Bulk Edit"
      handleSubmit={ () => dispatch(bulkUpdateAction(bulkUpdateValue)) }
      handleClose={ () => dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION)) }
      count={ itemsCount }
      upperCaseTitle={ title }
      isLoading={ isFetching }
    />
  );
};
export default BulkUpdateConfirmationDialog;
