import api from 'services/api';
import { closeModal } from 'store/modals/actions';
import { MODAL_RUN_USER_AGENT } from 'store/modals/modals';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import * as types from './types';
import { selectAgentList, selectAgentsSelected, selectLastSelected, selectTriggerAgentId, selectTriggerAgentWs, selectTriggerIgnoreInfo, selectTriggerResolveHostname } from './selectors';

function fetching () {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_FETCHING });
  };
}

function somethingWentWrong (errors) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_FAIL, errorMessage: 'There was an error, please try again.', errors });
  };
}

export function getUserAgents () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const userAgents = await api.agents.fetchUserAgents();
      dispatch({ type: types.AGENTS_GET_USER_AGENTS, userAgents });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function getCloudAgents () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const cloudAgents = await api.agents.fetchCloudAgents();
      dispatch({ type: types.AGENTS_GET_CLOUD_AGENTS, cloudAgents });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function removeAgent (agent) {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      await api.agents.removeAgent(agent.id);
      dispatch(getUserAgents());
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function getToken () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const responseSession = await api.faraday.getSession();

      const fd = new FormData();
      fd.set('csrf_token', responseSession.csrf_token);

      const responseToken = await api.agents.fetchToken(fd);
      dispatch({
        type: types.AGENTS_GET_TOKEN, token: responseToken.token, tokenExpiresIn: responseToken.expires_in, tokenDuration: responseToken.total_duration
      });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function getAgentTools (id) {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const response = await api.agents.fetchAgentTools(id);
      dispatch({ type: types.AGENTS_GET_TOOLS, id, tools: response.executors });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function runUserAgentTool (toolName, toolParams) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const agentId = selectTriggerAgentId(state);
      const workspaces = selectTriggerAgentWs(state).map((ws) => ws.name);
      const ignoreInfo = selectTriggerIgnoreInfo(state);
      const resolveHostname = selectTriggerResolveHostname(state);
      dispatch(fetching());
      const payload = {
        ignore_info: ignoreInfo,
        resolve_hostname: resolveHostname,
        workspaces_names: workspaces,
        executor_data: {
          agent_id: agentId,
          executor: toolName,
          args: toolParams
        }
      };
      await api.agents.runUserAgentTool(agentId, payload);

      dispatch(getUserAgents());
      dispatch(closeModal(MODAL_RUN_USER_AGENT));
      dispatch({ type: types.AGENTS_SUCCESS });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function runCloudAgentTool (wsSelected) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const agentId = selectTriggerAgentId(state);
      dispatch(fetching());

      await api.agents.runCloudAgent(agentId, wsSelected.map(w => w.primary));

      dispatch(getCloudAgents());
      dispatch(closeModal(MODAL_RUN_USER_AGENT));
      dispatch({ type: types.AGENTS_SUCCESS });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function resetWizard () {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_RESET });
  };
}

export function addTool (toolName) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_ADD_TOOL, tool: { name: toolName, variables: [] } });
  };
}

export function removeTool (tool) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_REMOVE_TOOL, tool });
  };
}

export function setFieldsTool (toolName, fields) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_SET_FIELDS_TOOL, toolName, fields });
  };
}

export function getManifest () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const data = await api.manage.fetchManifest();
      dispatch({ type: types.AGENTS_GET_MANIFEST, data });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export const setAgent = (data) => (dispatch) => {
  dispatch({ type: types.SET_NEW_AGENT, data });
};

export function clearAgent () {
  return (dispatch) => {
    dispatch({ type: types.RESET_NEW_AGENT });
  };
}

export function setTriggerAgentWs (workspaces) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_RUN_SET_WORKSPACES, workspaces });
  };
}

export function addTriggerAgentWs (ws) {
  return (dispatch, getState) => {
    const addedWorkspaces = selectTriggerAgentWs(getState());
    const allWorkspaces = selectActiveWorkspaces(getState());

    const newWs = allWorkspaces.find((workspace) => workspace.id === ws.id);
    if (newWs) {
      const newWorkspaces = [
        ...addedWorkspaces.filter((workspace) => workspace.id !== ws.id),
        newWs
      ];

      dispatch(setTriggerAgentWs(newWorkspaces));
    }
  };
}

export function removeTriggerAgentWs (ws) {
  return (dispatch, getState) => {
    const addedWorkspaces = selectTriggerAgentWs(getState());
    const newWorkspaces = addedWorkspaces.filter((w) => w.id !== ws.id);
    dispatch(setTriggerAgentWs(newWorkspaces));
  };
}

export function setIgnoreInfo (ignoreInfo) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_RUN_SET_IGNORE_INFO, ignoreInfo });
  };
}

export function setResolveHostname (resolveHostname) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_RUN_SET_RESOLVE_HOSTNAME, resolveHostname });
  };
}

// Click Logic

const addDeleteController = (agents, agentList) => {
  const allAgentsAreSelected = agents.every((testAgent) => agentList.some((agent) => agent.id === testAgent.id));

  if (allAgentsAreSelected) return [types.AGENT_UNSELECTED, agents];
  return [types.AGENT_SELECTED, agents];
};

const selectCalculator = (e, agent, agentList, areAgentsSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = agentList.findIndex((el) => el.id === agent.id);
  const agentsSelected = selectAgentsSelected(getState());

  dispatch({ type: types.NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areAgentsSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const agents = agentList.slice(start, end);
    const [type, payload] = addDeleteController(agents, agentsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([agent], agentsSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, agent) => (dispatch, getState) => {
  const allAgents = selectAgentList(getState());
  const agentSelected = selectAgentsSelected(getState());
  dispatch(selectCalculator(e, agent, allAgents, agentSelected.length > 0));
};

export const unSelectAll = () => (dispatch) => dispatch({ type: types.UNSELECT_ALL_AGENT });

export const selectAllAgents = () => (dispatch, getState) => {
  const agentsList = selectAgentList(getState());
  const agentsSelected = selectAgentsSelected(getState());

  if (agentsSelected.length === agentsList.length) return dispatch(unSelectAll());

  return dispatch({ type: types.SELECT_ALL_AGENT, agentsList });
};

export const toggleAgentsTab = () => (dispatch) => dispatch({ type: types.TOGGLE_AGENTS_TAB });
