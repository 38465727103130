import filter from 'lodash/filter';
import get from 'lodash/get';
import size from 'lodash/size';
import defaultFields from 'store/Workspace/fields';
import { updatePreferences } from 'store/Preferences/actions';
import { selectFields } from './selectors';

export function setFieldVisibility (field, value) {
  return async (dispatch, getState) => {
    let workspaces_table = selectFields(getState());

    if (!workspaces_table) workspaces_table = { ...defaultFields };
    if (!workspaces_table[field].locked) {
      workspaces_table[field].visible = value;

      if (value) workspaces_table[field].order = Object.keys(workspaces_table).filter((key) => workspaces_table[key].visible).length;
      else {
        let cont = 1;
        Object.keys(workspaces_table).filter((f) => workspaces_table[f].visible).sort((a, b) => (workspaces_table[a].order < workspaces_table[b].order)).forEach((f2) => {
          workspaces_table[f2].order = cont;
          cont += 1;
        });
      }

      dispatch(updatePreferences({ workspaces_table }));
    }
  };
}

export function setNewOrderColumns (columns) {
  return async (dispatch, getState) => {
    let workspaces_table = selectFields(getState());
    if (!workspaces_table) workspaces_table = { ...defaultFields };

    const fixedColumns = size(filter(workspaces_table, { locked: true }));

    columns.forEach((column) => {
      if (column) {
        if (!get(workspaces_table, `${column}.locked`, false)) {
          workspaces_table[column].order = columns.indexOf(column) + 1 + fixedColumns;
        }
      }
    });

    dispatch(updatePreferences({ workspaces_table }));
  };
}

export function resetDefault () {
  return async (dispatch) => {
    dispatch(updatePreferences({ workspaces_table: defaultFields }));
  };
}
