const defaultFields = {
  importance: {
    visible: true, order: 1, locked: true, maxWidth: 90, minWidth: 90, defaultMin: 90
  },
  name: {
    visible: true, order: 2, locked: true, maxWidth: 207, minWidth: 207, defaultMin: 207
  },
  description: {
    visible: true, order: 3, maxWidth: 250, minWidth: 250, defaultMin: 250
  },
  create_date: {
    visible: true, order: 4, maxWidth: 140, minWidth: 140, defaultMin: 140
  },
  vulnerabilities: {
    visible: true, order: 5, maxWidth: 110, minWidth: 110, defaultMin: 110
  },
  hosts: {
    visible: true, order: 6, maxWidth: 100, minWidth: 80, defaultMin: 80
  },
  services: {
    visible: true, order: 7, maxWidth: 70, minWidth: 70, defaultMin: 70
  },
  range_dates: {
    visible: true, order: 8, maxWidth: 150, minWidth: 150, defaultMin: 150
  },
  update_date: {
    visible: true, order: 9, maxWidth: 140, minWidth: 140, defaultMin: 140
  },
  readonly: {
    visible: true, order: 10, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  active: {
    visible: true, order: 11, maxWidth: 100, minWidth: 100, defaultMin: 100
  }
};

export default defaultFields;
