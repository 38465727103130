import React from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { redirectToManageDetail, selectVulnRow, unSelectAll } from 'store/Contextualization/AssetDetail/actions';
import { NameWrapper, Name } from './styled';
import Skeleton from 'Common/Components/Skeleton';

const NameCellVulnAsset = ({ rowInfo, showSkeleton }) => {
  const vulnName = get(rowInfo, 'name', '');
  const vulnId = get(rowInfo, '_id', 0);
  const dispatch = useDispatch();
  const confirmed = get(rowInfo, 'confirmed', false);

  const handleClick = (e) => {
    dispatch(unSelectAll('vulns'));
    dispatch(redirectToManageDetail(vulnId));
    dispatch(selectVulnRow(e, rowInfo));
  };

  if (showSkeleton) return <Skeleton />;
  return (
    <NameWrapper>
      <Name onClick={ handleClick } confirmed={ confirmed }>{ vulnName }</Name>
    </NameWrapper>
  );
};

export default NameCellVulnAsset;
