import styled from 'styled-components';
import { ReactComponent as IconWorkspace_ } from 'Images/manage.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  margin-right: auto;
`;
Wrapper.displayName = 'Wrapper';

export const InputTitle = styled.input`
  padding-right: 10px;
  margin: auto 5px auto 0px;
  /* flex: 1 1 auto; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: #264d6d;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;

  &:focus {
    cursor: text;
    color: #264d6d;
  }

  &:not(:focus) {
    &:hover {
      color: #264d6d;
      background-color: #EDF2F7;
    }
  }

`;
InputTitle.displayName = 'InputTitle';

export const IconWorkspace = styled(IconWorkspace_)`
  margin-right: 11px !important;
`;
IconWorkspace.displayName = 'IconWorkspace_';
