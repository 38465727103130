import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import {
  selectReadOnly, selectPermissions, selectAttachments
} from 'store/Manage/selectors';
import get from 'lodash/get';
import UploadEvidence from 'Common/Components/EvidenceTab/components/UploadEvidence';
import Attachment from 'Common/Components/EvidenceTab/components/Attachment';
import { Wrapper } from 'Common/Components/EvidenceTab/styled';
import api from 'services/api';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

const EvidenceTab = () => {
  const [evidences, setEvidences] = useState({});
  const attachments = useSelector(selectAttachments);
  const currentVuln = useSelector((state) => selectDetail('vulns', state));
  const vulnId = get(currentVuln, '_id', 0);
  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const allowed = permissions.update.allowed;
  const workspace = useSelector(selectCurrentWorkspace);
  const files = attachments;

  const getEvidences = useCallback(async () => {
    try {
      const data = await api.attachments.getAttachments(workspace, vulnId);
      setEvidences(data);
    } catch (e) {
      setEvidences({});
    }
  }, [workspace, vulnId]);

  const deleteEvidence = useCallback(async (name) => {
    try {
      await api.attachments.removeAttachments(workspace, vulnId, name);
      const newEvidences = { ...evidences };
      delete newEvidences[name];
      setEvidences(newEvidences);
    } catch (e) {
      setEvidences(evidences);
    }
  }, [evidences, workspace, vulnId]);

  useEffect(() => {
    getEvidences();
  }, [getEvidences]);

  return (
    <>
    <Wrapper>
      {!readOnly && allowed && <UploadEvidence getEvidences={ getEvidences } isEditing /> }
      {Object.keys(files).map((key) => <Attachment key={ key } deleteEvidence={ deleteEvidence } evidence={ evidences[key] } name={ key } isEditing />)}
    </Wrapper>
    </>
  );
};

export default EvidenceTab;
