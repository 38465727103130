
import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { Trash } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import { showVulnTemplateModalDelete } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';

const DeleteButton = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showVulnTemplateModalDelete());

  return (
    <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } />
  );
};

export default DeleteButton;
