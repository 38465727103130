import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBulkUpdateField, setBulkUpdateValue } from 'store/Contextualization/AssetDetail/actions';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useIntl } from 'react-intl';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';

const ConfirmFalsePositive = () => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const isBulk = vulnsSelected.length > 1;
  const intl = useIntl();

  const onConfirmedEdit = (confirmed) => {
    dispatch(setBulkUpdateField('confirmed'));
    dispatch(setBulkUpdateValue(confirmed));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
  };

  return (
    <ContextSubMenu
      enabled={ isBulk }
      title={ intl.formatMessage({ id: 'manage.cm.edit.confirmFalsePositive' }) }
    >
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.confirm' }) } onClick={ () => onConfirmedEdit(true) } />
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.falsePositive' }) } onClick={ () => onConfirmedEdit(false) } />
    </ContextSubMenu>
  );
};

export default ConfirmFalsePositive;
