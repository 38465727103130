import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import IconButton from 'Common/Components/IconButton';
import Trash from './styled';
import DeleteConfirmationModal from '../../DeleteConfirmationModal';
import ModalWarning from 'Common/Components/ModalWarning';

// eslint-disable-next-line import/prefer-default-export
export const WsDeleteButton = () => {
  const selectedWs = useSelector(selectWorkspacesSelected) || [];
  const intl = useIntl();
  const noVulnsSelectedMessage = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'workspace' });

  // Define un único estado para el tipo de modal.
  const [modalType, setModalType] = useState(null); // 'warning', 'confirmation' o null

  const onClick = () => setModalType('confirmation');

  const closeModal = () => {
    setModalType(null);
  };

  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ onClick } />
      {modalType === 'confirmation' && <DeleteConfirmationModal onCloseCallback={ closeModal } />}
      {modalType === 'warning' && (
        <ModalWarning
          onCloseCallback={ closeModal }
          title='Delete Workspace'
          description={ (selectedWs.length === 0) ? noVulnsSelectedMessage : 'The Workspace you´re currently using can´t be deleted. Switch to a different Workspace and retry.' }
        />
      )}
    </>
  );
};
