import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import { setBulkUpdateValue } from 'store/Host/actions';
import { selectModalBulkUpdateValue } from 'store/Host/selectors';

const StringInput = ({ name }) => {
  const dispatch = useDispatch();
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);

  const onChange = (value) => dispatch(setBulkUpdateValue(value));

  return (
    <DebouncedInput
      value={ bulkUpdateValue }
      onChange={ onChange }
      name={ name }
      title={ name }
      focusOnMount
    />
  );
};

export default StringInput;
