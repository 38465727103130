import React, { useState } from 'react';
import ActivityFeed from 'Screens/Contextualization/Dashboard/components/ToolbarItems/ActivityFeed';
import CommandHistory from 'Screens/Contextualization/Dashboard/components/ToolbarItems/CommandHistory';
import { Wrapper, Tabs, Tab } from './styled';

const Feeds = () => {
  const [tabActive, setActiveTab] = useState(0);

  return (
    <Wrapper>
      <Tabs>
        <Tab active={ tabActive === 0 } onClick={ () => setActiveTab(0) }>Activity Feed</Tab>
        <Tab active={ tabActive === 1 } onClick={ () => setActiveTab(1) }>Command History</Tab>
      </Tabs>
      { tabActive === 0 && <ActivityFeed />}
      { tabActive === 1 && <CommandHistory />}
    </Wrapper>
  );
};

export default Feeds;
