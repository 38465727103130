import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFetching, selectServices, selectServicesSelected } from 'store/Services/selectors';
import getColumns from './components/Columns';
import { selectFields } from 'store/Preferences/service/selectors';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import { getServices } from 'store/Services/actions';

const ServicesTable = () => {
  const servicesData = useSelector(selectServices);
  const isFetching = useSelector(selectIsFetching);
  const fields = useSelector(selectFields);
  const columns = getColumns(fields, isFetching);
  const servicesSelected = useSelector((state) => selectServicesSelected(state));
  const mockData = [...Array(15).keys()];
  const dispatch = useDispatch();

  const getTrProps = (rowInfo, servicesSelected) => {
    const selected = servicesSelected.find((vuln) => vuln._id === rowInfo.original._id);
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          background: selected ? '#edf2f7' : 'transparent'
        }
      };
    }
    return {};
  };

  const getThProps = (column, fields) => {
    const defaultMin = fields[column.id] ? fields[column.id].defaultWidth : 0;
    const maxWidth = fields[column.id] ? fields[column.id].maxWidth : 0;
    const response = { minWidth: defaultMin, maxWidth };
    return {
      style: defaultMin ? response : {}
    };
  };

  const fetchData = () => !isFetching && dispatch(getServices());

  return (
    <CustomReactTable
      data={ isFetching ? mockData : servicesData }
      columns={ columns }
      minRows={ 0 }
      manual
      onFetchData={ fetchData }
      showPagination={ false }
      getTrProps={ (_, rowInfo) => getTrProps(rowInfo, servicesSelected) }
      getTheadThProps={ (_, __, column) => getThProps(column, fields) }
      noDataText="Currently, there are no records to display"
    />
  );
};

export default ServicesTable;
