import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectServiceRow } from 'store/Contextualization/AssetDetail/actions';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import Checkbox from 'Common/Components/Checkbox';
import Skeleton from 'Common/Components/Skeleton';

export const CheckColum = ({ service, showSkeleton }) => { // eslint-disable-line import/prefer-default-export
  const servicesSelected = useSelector((state) => selectSelected('services', state));
  const dispatch = useDispatch();
  const isSelected = servicesSelected.find((serviceSelected) => serviceSelected._id === service._id);
  const handleClick = (e) => dispatch(selectServiceRow(e, service));

  if (showSkeleton) return <Skeleton />;
  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
