import React, { useEffect, useState } from 'react';
import { getActivityFeed } from 'Screens/Contextualization/Dashboard/actions/Actions';
import { selectActiveFeeds, selectIsFetching } from 'store/Dashboard/selectors';
import get from 'lodash/get';
import find from 'lodash/find';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter } from 'store/Filters/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import DateFormatter from 'Common/Components/DateFormatter';
import { useDispatch, useSelector } from 'react-redux';
import {
  Author, Vulnerabilities, HostsAndServices, StyledCol, Tag, Time, Wrapper, Empty, Tool,
  WrapperActivity, ActivityDescription, ActivityFooter, AgentIcon, ReportIcon, Dark, Light,
  ShellIcon, Center
} from './styled';
import { ButtonLink } from 'Common/Components/ButtonLink/styled';
import SpinnerComponent from 'Common/Components/SpinnerContainer';
import formatNumber from 'Common/Functions/FormatNumber';

const ActivityFeed = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectActiveFeeds);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const [page, setPage] = useState(1);
  const isFetching = useSelector(selectIsFetching);

  const viewMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    dispatch(getActivityFeed(page));
  }, [dispatch, page, workspaceSelected]);

  const onClick = (command) => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.commandIdFilter(command.toString())));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  let content = <div />;

  if (isFetching) return <SpinnerComponent height={ '250px' } />;

  if (get(data, 'activities.length', 0) > 0) {
    const elements = data.activities.slice(0, data.activities.length).filter((x) => x.hosts_count > 0 || x.services_count > 0 || x.vulnerabilities_count > 0);
    content = elements.map((activity, index) => {
      const isAgent = activity.import_source === 'agent';
      const isReport = activity.import_source === 'report';
      const isShell = activity.import_source === 'shell';
      let author = activity.creator;
      let fileName = activity.params;

      if (isAgent) {
        author = activity.command;
      } else if (isReport) {
        author = activity.creator;
        const index = activity.params.indexOf('_');
        fileName = activity.params.slice(index + 1);
      }

      const severity = find([
        { name: 'critical', value: activity.criticalIssue },
        { name: 'high', value: activity.highIssue },
        { name: 'med', value: activity.mediumIssue },
        { name: 'low', value: activity.lowIssue },
        { name: 'info', value: activity.infoIssue }
      ], (serverity) => serverity.value > 0);

      return (
        <WrapperActivity onClick={ () => onClick(activity._id) } key={ `dashboard_activity_feed_${index}` } severity={ get(severity, 'name', '') }>
          <ActivityDescription>
            <Vulnerabilities>
              { `${formatNumber(activity.vulnerabilities_count)} Vulnerabilities.` }
            </Vulnerabilities>
            <HostsAndServices>
              { activity.hosts_count ? `${formatNumber(activity.hosts_count)} Assets,` : 'No Assets,' }
              { activity.services_count ? ` ${formatNumber(activity.services_count)} Services.` : ' no Services.' }
            </HostsAndServices>
          </ActivityDescription>
          { isAgent && (
          <Author>
            <Light>Triggered by: </Light>
            <Dark>{ author }</Dark>
          </Author>
          )}
          { isReport && (
          <Author>
            <Dark>{ author }</Dark>
            <Light>imported </Light>
            <Dark>{ fileName }</Dark>
          </Author>
          ) }
          <ActivityFooter>
            <Time><DateFormatter date={ activity.date } /></Time>
            <Tag>
              { isAgent && <AgentIcon /> }
              { isReport && <ReportIcon /> }
              { isShell && <ShellIcon /> }
              <Tool>{ activity.tool }</Tool>
            </Tag>
          </ActivityFooter>
        </WrapperActivity>
      );
    });
  } else {
    content = <Empty>No feeds has been found yet.</Empty>;
  }

  const getDataTotal = () => get(data, 'activities.length', 0);
  const isEmpty = data?.activities?.filter((x) => x.hosts_count > 0 || x.services_count > 0 || x.vulnerabilities_count > 0).length === 0;

  return (
    <Wrapper>
      <StyledCol>
        {content}
        <Center>{ (getDataTotal() > (page * 15) && !isEmpty) && <ButtonLink isLoading={ isFetching } onClick={ viewMore }>Show more</ButtonLink> }</Center>
      </StyledCol>
    </Wrapper>
  );
};

export default ActivityFeed;
