const initialState = {
  host: null,
  vulns: {
    isFetching: false,
    data: [],
    fields: {
      id: false,
      name: true,
      tags: true,
      date: true,
      status: true,
      confirmed: true,
      severity: true
    },
    page: 1,
    rowsPerPage: 50,
    order_by: 'name',
    order_by_dir: 'desc',
    error: false,
    errorMessage: '',
    count: 0,
    lastSelected: -1,
    selected: [],
    selectedVulns: [],
    detail: undefined,
    showDeleteConfirmation: false,
    bulkUpdateField: '',
    bulkUpdateValue: ''
  },
  createOrEditService: {
    error: false,
    errorMessage: '',
    saved: false,
    selectedService: null
  },
  services: {
    isFetching: false,
    data: [],
    fields: {
      id: false,
      name: true,
      version: true,
      protocol: true,
      status: true,
      vulns: true,
      credentials: true,
      tags: true,
      port: true,
      owned: true
    },
    page: 1,
    rowsPerPage: 50,
    order_by: 'name',
    order_by_dir: 'desc',
    error: false,
    errorMessage: '',
    count: 0,
    lastSelected: -1,
    selected: [],
    detail: undefined,
    showDeleteConfirmation: false
  },
  tools: {
    isFetching: false,
    data: [],
    fields: {
      id: false,
      command: true,
      user: true,
      params: true,
      create_date: true
    }
  },
  vulnsGrouped: []
};

export default initialState;
