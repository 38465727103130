import styled from 'styled-components';

export const TabWrapper = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  padding: 16px 32px;
  cursor: pointer;
  color: #264d6d;
  font-size: 14px;
  font-weight: 500;
  background-color: ${({ isActive }) => (isActive ? '#ffffff' : '#edf2f7')};
`;
