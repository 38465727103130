import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 16px;
  width: 162px;
  ${({ isDisabled }) => isDisabled && 'cursor: not-allowed'};
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
