import React from 'react';
import Warning from 'Common/Components/Warning';
import { Wrapper, ModalWrapper } from 'Common/Components/ConfirmNavigationModal/styled';
import { StyledMessage, Asterisk } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { setCreateVulnError } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import useKeypress from 'Hooks/useEscKey';
import { selectErrorMessageCreateVuln, selectErrorTitleCreateVuln } from 'store/ManageEditCreate/selectors';

const Message = ({ errorTitle, errorMessage }) => {
  if (errorTitle === 'Create New Vulnerability') return <StyledMessage>{ errorMessage } ( <Asterisk /> )</StyledMessage>;
  return <StyledMessage>{ errorMessage }</StyledMessage>;
};
const CreationWarning = () => {
  const dispatch = useDispatch();
  const errorTitle = useSelector(selectErrorTitleCreateVuln);
  const errorMessage = useSelector(selectErrorMessageCreateVuln);

  useKeypress('Escape', () => {
    dispatch(setCreateVulnError(false, '', ''));
  });

  return (
    <Wrapper>
      <ModalWrapper>
        <Warning
          title={ errorTitle }
          children={ <Message errorTitle={ errorTitle } errorMessage={ errorMessage } /> }
          onClose={ () => dispatch(setCreateVulnError(false, '', '')) }
        />
      </ModalWrapper>
    </Wrapper>
  );
};

export default CreationWarning;
