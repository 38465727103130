import { updatePreferences } from 'store/Preferences/actions';

export function setTourFinished (status) {
  return async (dispatch) => {
    const preferences = { tourFinished: status };
    dispatch(updatePreferences(preferences));
  };
}

export function setNewTourFinished (data) {
  return async (dispatch) => {
    dispatch(updatePreferences(data));
  };
}
