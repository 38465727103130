import styled from 'styled-components';
import colors from 'Styles/colors';
import { Title } from 'Common/Components/StringField/styled';

export const CalendarWrapper = styled.div`
  position: absolute;
  background-color: white;
  box-shadow: 2px 2px 30px 0 #d5dee6;
  height: 300px;
  z-index: 1;
  
  .DateRangePicker__CalendarSelection {
    background-color: ${colors.softBlue};
    border: 1px solid ${colors.softBlue};
  }

  .DateRangePicker__CalendarHighlight {
    background-color: ${colors.softBlue};
    border: ${colors.softBlue};
    color: white;
  }

  .DateRangePicker__Date--is-selected{
    .DateRangePicker__DateLabel {
      color: white !important;
    }
  }

  .DateRangePicker__Date--is-highlighted{
    .DateRangePicker__DateLabel {
      color: black;
    }

    .DateRangePicker__CalendarHighlight--single {
      background-color: transparent;
    }
  }

  .DateRangePicker__PaginationArrow--next{
    &:hover {
      background-color: transparent;
      color: black;
    }
  }

  .DateRangePicker__PaginationArrow--previous{
    &:hover {
      background-color: transparent;
      color: black;
    }
  }

  .DateRangePicker__Month{
    width: ${(props) => props.width}px;
  }

  .DateRangePicker__Date--is-selected {
    background-color: ${colors.softBlue};
  }

  .DateRangePicker__PaginationArrow{
    height: 25px;
  }

  .DateRangePicker__WeekdayHeading {
    font-size: 11px;
  }

  .DateRangePicker__MonthHeaderLabel{
    font-size: 12px;
  }

  .DateRangePicker__DateLabel{
    font-size: 11px;
  }
`;
CalendarWrapper.displayName = 'CalendarWrapper';

export const StringFieldContainer = styled.div`

  input {
    background-color: ${colors.iceBlue} !important;
  }

  input::placeholder {
    color: ${({ highlight }) => (highlight ? 'black' : '')};
    font-weight: 300;
    font-size: 14.5px;
  }

  input:-ms-input-placeholder {
    color: ${({ highlight }) => (highlight ? 'black' : '')};
  }

  input::-ms-input-placeholder {
    color: ${({ highlight }) => (highlight ? 'black' : '')};
  }

  input:focus::placeholder { 
    background-color: ${colors.iceBlue} !important;
    color: ${({ highlight }) => (highlight ? 'black' : '#afb1bc')} !important;
  }

  ${Title}{
    margin-bottom: ${(props) => (props.hasMargin ? '12px' : '0px')};
  }
`;
StringFieldContainer.displayName = 'StringFieldContainer';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const CloseButton = styled.button`
  position: absolute;
  width: 50px;
  height: 25px;
  bottom: 8px;
  right: 19px;
  border: 0;
  background-color: ${colors.softBlue};
  color: white;
  font-size: 12px;
  border-radius: 2px;
`;
CloseButton.displayName = 'CloseButton';

export const CleanButton = styled.button`
  position: absolute;
  width: 50px;
  height: 25px;
  bottom: 8px;
  left: 19px;
  color: white;
  font-size: 12px;
  border: solid 1px ${colors.dark6};
  color: ${colors.dark2};
  background-color: white;
  border-radius: 2px;
`;
CleanButton.displayName = 'CleanButton';
