import React from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { Wrapper, Text, GoToButton, GoToIcon } from './styled';
import {
  workspaceSelectedFromTable, unSelectAll, selectRow, redirectToWsDetail
} from 'Screens/Contextualization/Workspaces/actions/Actions';
import Skeleton from 'Common/Components/Skeleton';

const Name = ({ isFetching, ws }) => {
  const dispatch = useDispatch();
  const name = get(ws, 'name', '');
  const isWsActive = get(ws, 'active', false);

  const handleClick = (e) => isWsActive && dispatch(workspaceSelectedFromTable(ws));

  const openWsDetail = (e) => {
    dispatch(unSelectAll());
    dispatch(selectRow(e, ws));
    dispatch(redirectToWsDetail(ws.name));
  };

  if (isFetching) return <Skeleton />;
  return (
    <Wrapper>
      <Text onClick={ openWsDetail } children={ name } />
      <GoToButton onClick={ handleClick } title="Explore Workspace">
        <GoToIcon />
      </GoToButton>
    </Wrapper>
  );
};

export default Name;
