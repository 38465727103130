import React from 'react';
import { Wrapper, Label, LightLabel } from './styled';
import { WsAddButton } from '../WSActionBar/components/LeftGroup';

const EmptyState = () => {
  return (
    <Wrapper>
      <Label>You currently have 0 Workspaces</Label>
      <LightLabel>Start by creating one</LightLabel>
      <WsAddButton />
    </Wrapper>
  );
};

export default EmptyState;
