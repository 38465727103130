import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  user-select: none;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  color: ${colors.blue6};
  font-weight: 600;
  font-size: 17px;
  margin: 13px 0;
`;
Title.displayName = 'Title';

export const Text = styled.div`
  font-size: 13px;
  font-weight: 100;
  color: ${colors.grey19};
`;
Text.displayName = 'Text';

export const Br = styled.span`
  color: ${colors.blue6};
  font-weight: 600;
`;
Br.displayName = 'Br';
