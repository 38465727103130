import React from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { newGetVulns } from 'store/Manage/filterActions';
import { removeCustomFilter, applyCustomFilter, setSaveEnabled } from 'store/Filters/actions';
import { getData } from 'store/Host/actions';
import { getVulnsByAsset } from 'store/Contextualization/AssetDetail/actions';
import get from 'lodash/get';
import { shouldRedirect } from 'Common/Components/SearchBar/components/BasicFilter/functions';
import { CFLabel, CFContainer, RemoveIcon } from './styled';

const CustomFilterButton = ({ filter, entity }) => {
  const dispatch = useDispatch();
  const entityName = get(entity, 'name.value', 'vulns');
  const entityPathname = get(entity, 'pathname', '');

  const deleteCustomFilter = () => dispatch(removeCustomFilter(entityName, filter.id));

  const setCustomFilter = () => {
    dispatch(shouldRedirect(entityPathname));
    dispatch(applyCustomFilter(entityName, filter.id));
    if (entityName === 'vulns') dispatch(newGetVulns());
    else if (entityName === 'assets') dispatch(getData());
    else if (entityName === 'vulnsAssets') dispatch(getVulnsByAsset());
    dispatch(setSaveEnabled(true));
  };

  return (
    <CFContainer>
      <CFLabel title={ filter.name } onClick={ setCustomFilter }>{filter.name}</CFLabel>
      <RemoveIcon onClick={ deleteCustomFilter } />
    </CFContainer>
  );
};

CustomFilterButton.propTypes = {
  filter: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired
};

export default CustomFilterButton;
