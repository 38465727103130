import { Accept, Cancel } from 'Common/Components/Button/styled';
import Checkbox from 'Common/Components/Checkbox';
import StandardDropdown from 'Common/Components/StandarDropdown';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { resetAddEditService, addService, editService } from 'store/HostDetail/actions';
import { selectSelectedService } from 'store/HostDetail/selectors';
import {
  Wrapper, Title, WrapperRow, Name, Port, Protocol, Version, Description, WrapperButtons, Error, WrapperField, Label, GearIcon
} from './styled';

const PORT_MIN = 0;
const PORT_MAX = 65535;

const AddEditService = ({ onClose }) => {
  const selectedService = useSelector(selectSelectedService);
  const getService = (elem, defaultValue) => get(selectedService, elem, defaultValue);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [name, setName] = useState(getService('name', ''));
  const [ports, setPorts] = useState(getService('ports', ''));
  const [protocol, setProtocol] = useState(getService('protocol', ''));
  const [version, setVersion] = useState(getService('version', ''));
  const [description, setDescription] = useState(getService('description', ''));
  const [status, setStatus] = useState(getService('status', ''));
  const [owned, setOwned] = useState(getService('owned', false));
  const statusOptions = [{ name: 'open', desc: 'Open' }, { name: 'closed', desc: 'Closed' }, { name: 'filtered', desc: 'Filtered' }];

  const validatePort = () => !(PORT_MIN <= ports && ports <= PORT_MAX) && setPorts('');

  const texts = {
    title: intl.formatMessage({ id: selectedService ? 'host.detail.services.addEditService.editTitle' : 'host.detail.services.addEditService.createTitle' }),
    cancel: intl.formatMessage({ id: 'host.detail.services.addEditService.cancel' }),
    save: intl.formatMessage({ id: 'host.detail.services.addEditService.save' }),
    name: intl.formatMessage({ id: 'host.detail.services.addEditService.name' }),
    port: intl.formatMessage({ id: 'host.detail.services.addEditService.port' }),
    protocol: intl.formatMessage({ id: 'host.detail.services.addEditService.protocol' }),
    version: intl.formatMessage({ id: 'host.detail.services.addEditService.version' }),
    description: intl.formatMessage({ id: 'host.detail.services.addEditService.description' })
  };

  const props = useSelector((state) => ({
    host: state.hostDetail.host,
    saved: get(state, 'hostDetail.createOrEditService.saved', false),
    errorMessage: get(state, 'hostDetail.createOrEditService.errorMessage', '')
  }));

  useEffect(() => () => dispatch(resetAddEditService()), [dispatch]);

  const onSave = () => {
    if (name && ports && protocol && status) {
      if (selectedService) {
        dispatch(editService(name, props.host.id, selectedService._id, status, ports.toString().split(','), protocol, version, description, owned));
      } else {
        dispatch(addService(name, props.host.id, status, ports.toString().split(','), protocol, version, description, owned));
      }
    }
  };

  useEffect(() => {
    if (props.saved) onClose();
  }, [props.saved, onClose]);

  return (
    <Wrapper>
      <WrapperRow>
        <Title>{ texts.title }</Title>
        <WrapperButtons>
          <Cancel onClick={ onClose }>{ texts.cancel }</Cancel>
          <Accept onClick={ onSave } disabled={ !name || !ports || !protocol || !status }>{ texts.save }</Accept>
        </WrapperButtons>
        <GearIcon />
      </WrapperRow>
      <WrapperRow>
        <Name title={ texts.name } name="name" value={ name } onChange={ (value) => setName(value) } />
        <StandardDropdown
          field="status"
          options={ statusOptions }
          updateValue={ (field, value) => setStatus(value) }
          placeholder="Status"
          defaultValue={ status }
        />
      </WrapperRow>
      <WrapperRow>
        <Port type="number" min={ PORT_MIN } max={ PORT_MAX } title={ texts.port } name="port" value={ ports } onChange={ setPorts } onBlur={ validatePort } />
        <Protocol title={ texts.protocol } name="protocol" value={ protocol } onChange={ (value) => setProtocol(value) } />
        <Version title={ texts.version } name="version" value={ version } onChange={ (value) => setVersion(value) } />
      </WrapperRow>
      <WrapperRow>
        <Description title={ texts.description } name="description" value={ description } onChange={ (value) => setDescription(value) } />
      </WrapperRow>
      <WrapperField>
        <Checkbox onChange={ () => setOwned(!owned) } state={ owned } />
        <Label>Owned</Label>
      </WrapperField>
      <WrapperRow>
        { props.errorMessage && <Error>{ props.errorMessage }</Error> }
      </WrapperRow>
    </Wrapper>
  );
};

export default AddEditService;
