import styled, { keyframes } from 'styled-components';
import { ReactComponent as ShortLogo } from 'Images/faraday-logo-nav.svg';
import { ReactComponent as LongLogo } from 'Images/faraday_logo_product.svg';
import { ReactComponent as _Arrow } from 'Images/laquo.svg';
import SideBarItem from './components/SideBarItem';

export const Wrapper = styled.div`
  grid-row: 1/-1;
  grid-column: 1/2;
  background-color: #edf2f7;
  width: ${({ open }) => (open ? 240 : 68)}px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: width .2s linear;
`;

export const CompressedLogo = styled(ShortLogo)`
  margin: 23px 0 0 23px;
`;

export const FullLogo = styled(LongLogo)`
  margin: 23px 0 0px 23px;
`;

export const Logo = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 26px;
`;

export const ToggleItem = styled(SideBarItem)`
  margin-top: auto;
`;

export const Arrow = styled(_Arrow)`
  margin-top: auto;
  margin-left: 27px;
  margin-bottom: 23px;
  ${({ $isOpen }) => !$isOpen && 'transform: rotate(180deg)'};
`;

const slideInRightKeyframes = keyframes`
  from { opacity: 0; transform: translate3d(-30px, 0, 0);}
  to { opacity: 1; transform: translate3d(0, 0, 0); }
`;

export const LogoText = styled.div`
  margin-left: 13px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: .5px;
  animation: ${slideInRightKeyframes} 0.25s forwards ease;
`;
