import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectReadOnly } from 'store/Faraday/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_APPLY_TEMPLATE } from 'store/modals/modals';
import IconButton from 'Common/Components/IconButton';
import TemplateIcon from './styled';
import ModalWarning from 'Common/Components/ModalWarning';

const ApplyTemplate = ({ hasVulns, vulnsSelected }) => {
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly && hasVulns;
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerabilities' });

  const handleClick = () => {
    if (canUpdate) {
      if (vulnsSelected.length > 0) dispatch(openModal(MODAL_APPLY_TEMPLATE));
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <TemplateIcon /> } title="Apply Template" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Apply Template" description={ message } /> }
    </>
  );
};

export default ApplyTemplate;
