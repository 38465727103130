import React from 'react';
import Select from 'react-select';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { updateVuln } from 'store/Manage/actions';
import { selectVulnDetail, selectPermissions } from 'store/Manage/selectors';
import { basicSelect } from 'Common/styles/style';
import { capitalize } from 'utils/stringUtils';
import { easeOfResolution } from 'Screens/Constants';
import Wrapper from './styled';
import { selectReadOnly } from 'store/Faraday/selectors';

const EaseOfResolutionPicker = () => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);

  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const vulnEaseOfResolution = get(vuln, 'easeofresolution', '');
  const value = {
    label: capitalize(vulnEaseOfResolution) || 'Ease of resolution',
    value: vulnEaseOfResolution || 'Ease of resolution'
  };
  return (
    <Wrapper isDisabled={ readOnly || !permissions.update.allowed }>
      <Select
        styles={ basicSelect }
        isSearchable={ false }
        isDisabled={ readOnly || !permissions.update.allowed }
        value={ value }
        onChange={ (e) => dispatch(updateVuln(vuln, 'easeofresolution', e.value)) }
        options={ easeOfResolution.map((element) => ({ label: element.desc, value: element.name })) }
      />
    </Wrapper>
  );
};

export default EaseOfResolutionPicker;
