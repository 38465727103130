import React from 'react';
import Vulnerabilities from 'Screens/Contextualization/Dashboard/components/ToolbarItems/Vulnerabilities';
import TopServices from 'Screens/Contextualization/Dashboard/components/ToolbarItems/TopServices';
import TopHosts from 'Screens/Contextualization/Dashboard/components/ToolbarItems/TopHosts';
import LatestVulns from 'Screens/Contextualization/Dashboard/components/ToolbarItems/LatestVulns';
import StatusChart from 'Screens/Contextualization/Dashboard/components/ToolbarItems/StatusChart';
import { Box } from 'Screens/Contextualization/Dashboard/components/ToolbarItems/Common/Box/styled';
import { StyledRow, StyledCol, SummaryStyledCol } from './styled';
import Feeds from 'Screens/Contextualization/Dashboard/components/ToolbarItems/Feeds';
import SpinnerComponent from 'Common/Components/SpinnerContainer';
import { useSelector } from 'react-redux';
import { selectIsFetching } from 'store/Dashboard/selectors';

const Layout = () => {
  const isLoadingVulns = useSelector(selectIsFetching);

  return (
  <StyledRow direction="row" marginBottom marginRight>

    <StyledCol width="100%" maxWidth="375px">
       <Feeds />
    </StyledCol>

    <StyledCol marginBottom width="45%">
      <SummaryStyledCol>
        <Vulnerabilities />
      </SummaryStyledCol>

      <StyledCol>
        <LatestVulns />
      </StyledCol>

      <StyledRow marginRight>
        <StyledCol width="calc(55% - 14px)">
          <Box>
            {isLoadingVulns ? <SpinnerComponent height={ '350px' } /> : <TopHosts />}
          </Box>
        </StyledCol>
        <StyledCol width="45%">
          <Box>
          {isLoadingVulns ? <SpinnerComponent height={ '350px' } /> : <TopServices />}
          </Box>
        </StyledCol>
      </StyledRow>
    </StyledCol>

    <StyledCol marginBottom width="30%">
      <StyledCol>
        <Box>
          {isLoadingVulns ? <SpinnerComponent /> : <StatusChart />}
        </Box>
      </StyledCol>
    </StyledCol>

  </StyledRow>
  );
};

export default Layout;
