import colors from 'Styles/colors';
import styled from 'styled-components';

const color = {
  'open': colors.warmPurple,
  're-opened': colors.redPink,
  'risk-accepted': colors.apple,
  'closed': colors.grey2
};

export const Wrapper = styled.div`
  height: 250px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.label`
  user-select: none;
  color: ${colors.dark2};
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
`;
Title.displayName = 'Title';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';

export const ContentWrapper = styled.div`
 @media(min-width: 1480px) {
  display: flex;
  justify-content: center;
 }
`;
ContentWrapper.displayName = 'ContentWrapper';

export const PieWrapper = styled.div`
  height: 190px;
  max-width: 255px;
  margin: 0 auto;
  @media(min-width: 1480px) {
    margin: 0;
    height: 200px;
    max-width: 275px;
  }
`;
PieWrapper.displayName = 'PieWrapper';

export const References = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media(min-width: 1480px) {
    flex-direction: column;
    justify-content: center;
  } 
`;
References.displayName = 'References';

export const Reference = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  &::before {
    width: 10px;
    height: 10px;
    margin-right: 6px;
    content: "";
    background-color:${({ status }) => color[status]};
    border-radius: 50%;
  }
  @media(min-width: 1480px) {
    margin-bottom: 8px;
  } 
`;
Reference.displayName = 'Reference';

export const FlyoutWrapper = styled.div`
  background-color: #FFF;
  border-radius: 2px;
  border: 1px solid #d9e4ef;
  text-align: center;
`;
FlyoutWrapper.displayName = 'FlyoutWrapper';

export const Status = styled.div`
  color: ${({ status }) => color[status]};
`;
Status.displayName = 'Status';

export const Value = styled.div`
  color: #63758d;
`;
Value.displayName = 'Value';
