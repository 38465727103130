import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import styled from 'styled-components';
import { selectAllAgents, selectRow } from 'store/Agents/actions';
import { allAgentsAreSelected, selectAgentsSelected, someAgentIsSelected } from 'store/Agents/selectors';

const CheckHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckHeader = () => {
  const allAgentsSelected = useSelector(allAgentsAreSelected);
  const someAgentSelected = useSelector(someAgentIsSelected);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(selectAllAgents());
  };

  return (
      <CheckHeaderContainer title="Select">
        <Checkbox theme="grey" onChange={ handleClick } state={ allAgentsSelected } partialChecked={ someAgentSelected && !allAgentsSelected } />
      </CheckHeaderContainer>
  );
};

export const CheckColum = ({ agent }) => {
  const agentsSelected = useSelector((state) => selectAgentsSelected(state));
  const dispatch = useDispatch();
  const isSelected = agentsSelected.find((agentSelected) => agentSelected.id === agent.id);
  const handleClick = (e) => {
    // dispatch(unSelectAll());
    dispatch(selectRow(e, agent));
  };
  return (
      <div title="Select">
        <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
      </div>
  );
};
