import colors from 'Styles/colors';
import styled from 'styled-components';
import { Col } from 'Common/Components/Grid';
import severityMapColors from 'Common/styled/common';

export const Title = styled.label`
  user-select: none;
  color: ${colors.dark2};
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
`;
Title.displayName = 'Title';

export const Text = styled.div`
  color: #4c6c8c;
  display: inline;
  font-size: 13px;
  font-weight: 500;
  line-height: 2.62;
`;
Text.displayName = 'Text';

export const Service = styled(Text)`
  display: inline;
  text-align: right;
  color: ${colors.blueGrey};
`;
Service.displayName = 'Service';

export const Target = styled(Service)`
  font-weight: 400;
`;
Target.displayName = 'Target';

export const TextWrapper = styled.div`
  width: ${(props) => (props.width ? `${props.width}` : undefined)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:first-child {
    margin-right: 10px;
  }
`;
Text.displayName = 'Text';

export const Severity = styled.div`
  background-color: ${(props) => severityMapColors[props.level] || colors.grey4};
  border-radius: 4px;
  color: ${colors.white};
  display: inline-block;
  font-size: 9px;
  font-weight: 900;
  height: 20px;
  line-height: 20px;
  margin: 10px 10px 10px 0;
  text-align: center;
  text-transform: uppercase;
  width: 24px;
`;
Severity.displayName = 'Severity';

export const StyledCol = styled(Col)`
  line-height: 34px;
  & > *:not(:last-child) {
    border-bottom: 1px solid ${colors.light2};
  }
`;
StyledCol.displayName = 'StyledCol';

export const LatestVulnEntry = styled.div`
  cursor: pointer;
`;
LatestVulnEntry.displayName = 'LatestVulnEntry';

export const TextContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
LatestVulnEntry.displayName = 'TextContainery';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';
