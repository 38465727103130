import styled from 'styled-components';
import colors from 'Styles/colors';
import { PreviewWrapper, StyledReactMarkdown } from 'Common/Components/v2/MarkdownEditor/MarkdownInput/components/MDPreview/styled';

export const Wrapper = styled(PreviewWrapper)`
  background-color: transparent;
  max-height: 38px;
  padding: 0;
`;
Wrapper.displayName = 'Wrapper';

export const StyledMd = styled(StyledReactMarkdown)`
  color: ${colors.dark5};
  font-size: 12.5px;
`;
StyledMd.displayName = 'StyledMd';
