import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFiltering, selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { allHostsAreSelected, selectHostCount, selectHostsSelected, selectSelectAllHosts } from 'store/Host/selectors';
import { selectTotalHosts, unSelectAll, unselectTotalHosts } from 'store/Host/actions';
import { Wrapper, Text, Blue, Bold } from 'Screens/Contextualization/Manage/components/ManageTable/components/SelecAll/styled';

const SelectAll = () => {
  const dispatch = useDispatch();
  const allVisualHostsSelected = useSelector(allHostsAreSelected);
  const pageLimit = useSelector((state) => selectRowsPerPage('assets', state));
  const page = useSelector((state) => selectPage('assets', state));
  const selectAll = useSelector(selectSelectAllHosts);
  const hostsSelected = useSelector(selectHostsSelected);
  const hostCount = useSelector(selectHostCount);
  const isFiltering = useSelector(state => selectIsFiltering('assets', state));

  const handleSelectAll = () => {
    dispatch(selectTotalHosts());
  };
  const handleClear = () => {
    dispatch(unselectTotalHosts());
    dispatch(unSelectAll());
  };

  const content = () => {
    if (selectAll) {
      return (
            <Text>
        All <Bold>{hostCount}</Bold> Assets on this {isFiltering ? 'search' : 'Asset'} are selected. <Blue onClick={ handleClear }>Clear Selection</Blue>
            </Text>
      );
    } else if (isFiltering) {
      return (
<Text>
        All <Bold>{hostsSelected.length}</Bold> Assets on this page are selected. <Blue onClick={ handleSelectAll }>Select all Assets that match this search.</Blue>
</Text>
      );
    } else {
      return (
<Text>
        All <Bold>{hostsSelected.length}</Bold> Assets on this page are selected. <Blue onClick={ handleSelectAll }>Select all {hostCount} Assets in Workspace.</Blue>
</Text>
      );
    }
  };
  if (!allVisualHostsSelected) return null;
  if ((hostsSelected.length < pageLimit && page === 1 && !allVisualHostsSelected) || hostCount <= pageLimit) return null;
  return <Wrapper> {content()} </Wrapper>;
};

export default SelectAll;
