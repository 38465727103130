import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectModalBulkUpdateField } from 'store/Host/selectors';
import selectModal from 'store/modals/selectors';
import { MODAL_MANAGE_BULK_UPDATE } from 'store/modals/modals';
import { setBulkUpdateValue, setBulkUpdateField } from 'store/Host/actions';
import get from 'lodash/get';
import BulkTitle from './components/BulkTitle';
import SubmitButtons from './components/SubmitButtons';
import { ModalContent, Wrapper, EditorWrapper } from './styled';
import BULK_UPDATE_COMPONENTS from './components/components';

const BulkUpdateModal = () => {
  const dispatch = useDispatch();

  const bulkUpdateField = useSelector(selectModalBulkUpdateField);
  const showModal = useSelector((state) => selectModal(state, MODAL_MANAGE_BULK_UPDATE));
  const bulkUpdateComponent = get(BULK_UPDATE_COMPONENTS, bulkUpdateField, null);

  useEffect(() => () => {
    dispatch(setBulkUpdateValue(''));
    dispatch(setBulkUpdateField(''));
  }, [dispatch]);

  if (!showModal) return null;
  return (
    <Wrapper>
      <ModalContent>
        <BulkTitle />
        <EditorWrapper>
          { bulkUpdateComponent }
        </EditorWrapper>
        <SubmitButtons />
      </ModalContent>
    </Wrapper>
  );
};

export default BulkUpdateModal;
