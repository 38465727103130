import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { selectWsDetail } from 'store/Workspace/selectors';
import debounce from 'lodash/debounce';
import { updateWS } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { Wrapper, InputTitle, IconWorkspace } from './styled';
import { LockIcon } from 'Screens/Contextualization/Workspaces/components/Table/LockUnlockWs/styled';

const Title = () => {
  const dispatch = useDispatch();
  const workspaceDetail = useSelector(selectWsDetail);
  const currentName = get(workspaceDetail, 'name', '');
  const [wsName, setWsName] = useState(workspaceDetail.name);
  const readOnly = get(workspaceDetail, 'readonly', false);

  useEffect(() => {
    setWsName(workspaceDetail.name);
  }, [workspaceDetail]);

  const updateName = useRef(debounce((name) => {
    if (name) {
      dispatch(updateWS(currentName, { name }));
    }
  }, 1000));

  const handleChange = (e) => {
    const name = e.target.value;
    setWsName(name);
    updateName.current(name);
  };

  return (
    <Wrapper>
      <IconWorkspace />
        <InputTitle value={ wsName } onChange={ handleChange } />
      { readOnly && <LockIcon title="This Workspace is locked (read-only)" /> }
    </Wrapper>
  );
};

export default Title;
