import React from 'react';
import { useSelector } from 'react-redux';
import { selectPathname } from 'store/Router/selectors';
import { selectVulnTemplateDetailId } from 'store/KnowledgeBase/selectors';
import { Agents, KbTitle } from './Titles';

const Title = () => {
  const pathname = useSelector((state) => selectPathname(state));
  const currentVulnTemplate = useSelector(selectVulnTemplateDetailId);
  const showKB = (pathname === '/knowledge_base') ||
  (pathname.includes('/knowledge_base/')) ||
  pathname === `/knowledge_base/${currentVulnTemplate}`;
  const agents = pathname === '/automation/agents';

  if (showKB) return <KbTitle />;
  if (agents) return <Agents />;
  return null;
};

export default Title;
