import React from 'react';
import { useSelector } from 'react-redux';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import Dashboard from 'Screens/Dashboard/components/Dashboard/index';
import DashboardContext from 'Screens/Contextualization/Dashboard/components/Dashboard/index';

const DashboardWrapper = () => {
  const showLegacyUI = useSelector(selectShowLegacyUI);

  return (
    <>
      {
        showLegacyUI
          ? <Dashboard />
          : <DashboardContext />
      }
    </>
  );
};

export default DashboardWrapper;
