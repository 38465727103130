import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { updateWS, refreshWs } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { selectIsRefreshingWs, selectWsDetail } from 'store/Workspace/selectors';
import Importance from 'Common/Components/Importance';
import { Wrapper, IconButton, RefreshIcon } from './styled';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

const TopButtons = () => {
  const dispatch = useDispatch();
  const wsSelected = useSelector(selectWsDetail);
  const wsName = get(wsSelected, 'name', '');
  const importance = get(wsSelected, 'importance', 0);
  const isRefreshingWs = useSelector(selectIsRefreshingWs);

  return (
    <Wrapper>
      <Importance importance={ importance } callback={ (importance) => dispatch(updateWS(wsName, { importance })) } />
      { isRefreshingWs
        ? <SpinnerComponent size="16px" containerHeight="30px" />
        : <IconButton title="Refresh" onClick={ () => dispatch(refreshWs((wsName))) }>
            <RefreshIcon />
          </IconButton>
      }
    </Wrapper>
  );
};

export default TopButtons;
