import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowDetail } from 'store/Manage/selectors';
import { redirectToManage } from 'store/Manage/actions';
import Title from '../Title';
import {
  Wrapper,
  CloseIconWrapper,
  CloseIcon
} from './styled';

const Header = () => {
  const dispatch = useDispatch();
  const show = useSelector(selectShowDetail);

  if (!show) return null;
  return (
    <Wrapper>
      <Title />
      <CloseIconWrapper onClick={ () => dispatch(redirectToManage()) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
