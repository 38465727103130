import React from 'react';
import { useDispatch } from 'react-redux';
import { redirectToVulnTemplate } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import Title from './Title';
import { Wrapper, CloseIconWrapper, CloseIcon } from './styled';

const Header = () => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Title />
      <CloseIconWrapper onClick={ () => dispatch(redirectToVulnTemplate()) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
