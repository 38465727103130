import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { dragCustomAttribute } from 'store/CustomAttributes/actions';
import AttributeList from 'Screens/Contextualization/Manage/components/CustomAttributesModal/components/AttributeList';
import Header from 'Screens/Contextualization/Manage/components/CustomAttributesModal/components/Header/index';
import AddCustomAttribute from 'Screens/Contextualization/Manage/components/CustomAttributesModal/components/AddCustomAttribute';
import SettingsHeader from '../Header';

const CustomAttributes = ({ onClose }) => {
  const dispatch = useDispatch();
  const handleDragEnd = (result) => dispatch(dragCustomAttribute(result));
  return (
      <>
        <SettingsHeader onAccept={ onClose } onClose={ onClose } />
        <Header customHeader />
        <AddCustomAttribute />
        <DragDropContext onDragEnd={ handleDragEnd }>
          <AttributeList />
        </DragDropContext>
      </>
  );
};
export default CustomAttributes;
