import { copy } from 'Common/Functions/Copy';
import * as types from './types';
import initialState from './initialState';
import uniq from 'lodash/uniq';
import get from 'lodash/get';

function host (state = initialState, action) {
  const { entity } = action;
  let entityState;

  if (entity) {
    entityState = get(state, entity, undefined);
  }

  switch (action.type) {
    case types.RESET_STATE_HOST_DETAIL: {
      return {
        ...copy(initialState)
      };
    }
    case types.SHOW_HOST_DETAIL: {
      return {
        ...state,
        host: action.host
      };
    }
    case types.HIDE_HOST_DETAIL: {
      return {
        ...state,
        host: null
      };
    }
    case types.GET_DATA_VULNS_START: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_VULNS_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: false,
          data: action.data,
          count: action.count
        }
      };
    }
    case types.GET_DATA_VULNS_FAIL: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: false,
          data: [],
          error: true,
          errorMessage: action.data
        }
      };
    }
    case types.GET_DATA_SERVICES_START: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_SERVICES_SUCCESS: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: action.data,
          count: action.count
        }
      };
    }
    case types.GET_DATA_SERVICES_FAIL: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: []
        },
        error: true,
        errorMessage: action.data
      };
    }
    case types.GET_DATA_TOOLS_START: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_TOOLS_SUCCESS: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: false,
          data: action.data
        }
      };
    }
    case types.GET_DATA_TOOLS_FAIL: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: false,
          data: []
        }
      };
    }
    case types.HOST_ADD_EDIT_SERVICE_FAIL: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          error: true,
          errorMessage: action.message,
          selectedService: initialState.createOrEditService.selectedService
        }
      };
    }
    case types.HOST_ADD_EDIT_SERVICE_SUCCESS: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          saved: true,
          error: initialState.createOrEditService.error,
          errorMessage: initialState.createOrEditService.errorMessage,
          selectedService: initialState.createOrEditService.selectedService
        }
      };
    }
    case types.HOST_ADD_EDIT_SERVICE_RESET: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          saved: initialState.createOrEditService.saved,
          error: initialState.createOrEditService.error,
          errorMessage: initialState.createOrEditService.errorMessage,
          selectedService: initialState.createOrEditService.selectedService
        }
      };
    }
    case types.SELECT_SERVICE: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          selectedService: action.selectedRow
        }
      };
    }

    case types.UPDATE_VULN_ASSET_PREVIEW_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          data: action.data,
          isFetching: false,
          count: action.vulnsCount,
          detail: action.vuln
        }
      };
    }
    case types.UPDATE_VULN_ASSET_PREVIEW_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.SERVICE_SELECTED: {
      return {
        ...state,
        services: {
          ...state.services,
          selected: uniq([...state.services.selected, ...action.payload]),
          selectedForWorking: uniq([...state.services.selected, ...action.payload])
        }
      };
    }
    case types.SERVICE_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newServicesSelected = action.payload.reduce((servicesSelected, serviceToDelete) => filterByID(servicesSelected, serviceToDelete._id), state.services.selected);

      return {
        ...state,
        services: {
          ...state.services,
          selected: newServicesSelected
        }
      };
    }

    case types.SELECT_ALL_ITEMS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          selected: [...action.list]
        }
      };
    }
    case types.UNSELECT_ALL_ITEMS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          selected: []
        }
      };
    }
    case types.SHOW_DETAIL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          detail: action.detail,
          showDetail: true
        }
      };
    }
    case types.HIDE_DETAIL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          detail: undefined,
          showDetail: false
        }
      };
    }
    case types.CLEAR_ERROR: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          error: false,
          errorMessage: ''
        }
      };
    }
    case types.SET_PAGE_NUMBER_SERVICES: {
      return {
        ...state,
        services: {
          ...state.services,
          page: action.pageNumber
        }
      };
    }
    case types.SHOW_MODAL_DELETE: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          showDeleteConfirmation: true
        }
      };
    }
    case types.HIDE_MODAL_DELETE: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          showDeleteConfirmation: false
        }
      };
    }
    case types.DELETE_START: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          showDeleteConfirmation: false,
          isFetching: true
        }
      };
    }
    case types.DELETE_SUCCESS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          selected: [],
          isFetching: false
        }
      };
    }
    case types.DELETE_FAIL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          isFetching: false
        }
      };
    }
    case types.RESET_SERVICE_DETAIL: {
      return {
        ...state,
        services: {
          ...state.services,
          detail: undefined
        }
      };
    }
    case types.UPDATE_SERVICE_SUCCESS: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: action.data,
          count: action.serviceCount
        }
      };
    }

    case types.SET_VULNS_TAB: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          selectedTab: action.vulnSelectedTab
        }
      };
    }

    case types.SET_HOST_FOR_WORKING: {
      return {
        ...state,
        hostForWorking: action.host
      };
    }

    default: {
      return state;
    }
  }
}

export default host;
