import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allVulnTemplatesAreSelected, someVulnTemplateIsSelected } from 'store/KnowledgeBase/selectors';
import Checkbox from 'Common/Components/Checkbox';
import { selectAllTemplate } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';

export const CheckHeader = () => { // eslint-disable-line import/prefer-default-export
  const allTemplateSelected = useSelector(allVulnTemplatesAreSelected);
  const someTemplateSelected = useSelector(someVulnTemplateIsSelected);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(selectAllTemplate());
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allTemplateSelected } partialChecked={ someTemplateSelected && !allTemplateSelected } />
  );
};
