import React from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { selectRow, redirectToVulnTemplateDetail, unSelectAll } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { NameWrapper, Name } from './styled';

const NameCell = ({ rowInfo }) => {
  const vulnTemplateName = get(rowInfo, 'name', '');
  const vulnTemplateId = get(rowInfo, 'id', 0);
  const dispatch = useDispatch();

  const handleClick = (e) => {
    dispatch(unSelectAll());
    dispatch(redirectToVulnTemplateDetail(vulnTemplateId));
    dispatch(selectRow(e, rowInfo));
  };
  return (
    <NameWrapper>
      <Name onClick={ handleClick }>{ vulnTemplateName }</Name>
    </NameWrapper>
  );
};

export default NameCell;
