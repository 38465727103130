import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomTextArea from 'Common/Components/CustomTextArea';
import { createUpdateWorkspace, resetCreationState, setField } from 'Screens/Workspaces/actions/Actions';
import {
  selectWsEditCreateName, selectWsEditCreateDescription,
  selectWsEditCreateStart, selectWsEditCreateEnd, selectWsEditCreateLocale, selectWsEditCreateErrorMessage,
  selectWsEditCreateId,
  selectWorkspaceSaved,
  selectIsFetching,
  selectScope,
  selectWsEditCreateImportance,
  selectWsEditCustomer,
  selectWsEditCreatePublic
} from 'store/WorkspaceEditCreate/selectors';
import BlueButton from 'Common/Components/BlueButton';
import ModalWrapper from 'Common/Components/ModalWrapper';
import CustomList from 'Common/Components/CustomList';
import {
  Modal, Title, CancelButton, Header, Body, FieldTitle, Field, FieldDescription, TextAreaContainer, FieldTitleDescription, ErrorMessage,
  TopRow, FieldScope, TextField, TextSpan, Flex
} from './styled';
import Importance from 'Common/Components/Importance';
import DateRangePicker from 'Common/Components/DateRangePicket';
import Checkbox from 'Common/Components/Checkbox';

const ModalCreation = ({ handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const name = useSelector(selectWsEditCreateName);
  const customer = useSelector(selectWsEditCustomer);
  const publicWS = useSelector(selectWsEditCreatePublic);
  const description = useSelector(selectWsEditCreateDescription);
  const start = useSelector(selectWsEditCreateStart);
  const end = useSelector(selectWsEditCreateEnd);
  const locale = useSelector(selectWsEditCreateLocale);
  const errorMessage = useSelector(selectWsEditCreateErrorMessage);
  const id = useSelector(selectWsEditCreateId);
  const workspaceSaved = useSelector(selectWorkspaceSaved);
  const isFetching = useSelector(selectIsFetching);
  const scope = useSelector(selectScope);
  const importance = useSelector(selectWsEditCreateImportance);
  const [isValidWsName, setIsValidWsName] = useState(true);

  const nameField = intl.formatMessage({ id: 'workspaces.modal.newWorkspace.name' });

  useEffect(() => () => dispatch(resetCreationState()), [dispatch]);

  useEffect(() => {
    if (workspaceSaved) handleClose();
  }, [handleClose, workspaceSaved]);

  const validateWsName = (value) => {
    if (value && (value.match(/[^A-Za-z0-9@._-]/))) setIsValidWsName(false);
    else setIsValidWsName(true);
  };

  const handleChange = (field, value) => {
    dispatch(setField(field, value));

    if (field === 'name') validateWsName(value);
  };

  const onChangeDebounce = debounce(handleChange, 250);

  const onCloseCalendar = (startDate, endDate) => {
    dispatch(setField('start', new Date(startDate)));
    dispatch(setField('end', new Date(endDate)));
  };

  const cleanDates = () => {
    dispatch(setField('start', ''));
    dispatch(setField('end', ''));
  };

  const onAddScope = (value) => dispatch(setField('scope', [...scope, value]));

  const onRemoveScope = (indexToRemove) => {
    const newScope = scope.filter((item, index) => index !== indexToRemove);
    dispatch(setField('scope', newScope));
  };

  return (
    <ModalWrapper>
      <Modal>
        <Header>
          <Title>{id ? <FormattedMessage id="workspaces.modal.edit.title" /> : <FormattedMessage id="workspaces.modal.create.title" />}</Title>
          <CancelButton onClick={ handleClose } id="modal-ws-cancel-btn">
            <FormattedMessage id="cancel" />
          </CancelButton>
          <BlueButton id="modal-ws-accept-btn" onClick={ () => dispatch(createUpdateWorkspace()) } disabled={ !name || !isValidWsName } isLoading={ isFetching } label={ id ? intl.formatMessage({ id: 'save' }) : intl.formatMessage({ id: 'create' }) } />
        </Header>
        <Body id="modalBody">
          <TopRow>
            <TextField
              title={ nameField }
              name="name"
              placeholder="New Workspace"
              value={ name }
              onChange={ (value) => handleChange('name', value) }
              error={ !isValidWsName }
              errorMessage="No spaces and no special chars (except _ and -) are allowed"
            />
            <Field index={ 1 } id="dateSection">
              <FieldTitle children={ <FormattedMessage id="workspaces.modal.newWorkspace.StartAndEndDate" /> } />
              <DateRangePicker id="modal-ws-date" width="100%" height="34px" start={ start } end={ end } onClose={ onCloseCalendar } locale={ locale } cleanDates={ cleanDates } />
            </Field>
          </TopRow>

          <FieldScope>
          <TextField
            fullwidth
            title="Customer"
            name="customer"
            placeholder="Customer Name"
            value={ customer }
            onChange={ (value) => handleChange('customer', value) }
          />
          </FieldScope>

          <FieldDescription id="descriptionSection">
            <FieldTitleDescription><FormattedMessage id="workspaces.modal.newWorkspace.description" /></FieldTitleDescription>
            <TextAreaContainer><CustomTextArea id="modal-ws-description" width="420px" height="55px" title="" placeholder={ intl.formatMessage({ id: 'workspaces.modal.newWorkspace.usedFor' }) } updateValue={ (value) => onChangeDebounce('description', value) } field="" defaultValue={ description } /></TextAreaContainer>
          </FieldDescription>
          <Flex justify="space-between" align="center">

          <Flex align="center">
          <Importance small importance={ importance } callback={ (value) => handleChange('importance', value) } />
          <TextSpan>Importance</TextSpan>
          </Flex>
          <div>
          <Checkbox state={ publicWS } onChange={ (e) => handleChange('public', !e.currentTarget.checked) } />
          <TextSpan>Make Workspace Public</TextSpan>
          </div>
          </Flex>

          <FieldScope>
            <FieldTitleDescription children={ <FormattedMessage id="workspaces.modal.newWorkspace.scope" /> } />
            <CustomList
              key="MODAL_WS_CREATION"
              placeholder="Scope target"
              addItem={ onAddScope }
              removeItem={ onRemoveScope }
              listData={ scope }
              responsive
              canUpdate
            />
          </FieldScope>
          <ErrorMessage children={ errorMessage } />
        </Body>
      </Modal>
    </ModalWrapper>
  );
};

ModalCreation.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ModalCreation;
