
import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: ${colors.darkBlueGrey};
  margin: 36px 0 37px 0;
`;
Label.displayName = 'Label';

export const LightLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${colors.grey19};
  margin-bottom: 17px;
`;
LightLabel.displayName = 'LightLabel';
