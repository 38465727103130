import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
Wrapper.displayName = 'Wrapper';

export const ButtonWrapper = styled.div`
  position: relative;
`;
ButtonWrapper.displayName = 'ButtonWrapper';
