import React from 'react';
import Table from './Table';
import Wrapper from './styled';
import BulkUpdateModal from './BulkUpdateModal';
import BulkUpdateConfirmationDialog from 'Screens/Contextualization/Manage/components/ManageTable/components/BulkUpdateConfirmationDialog';
import { useSelector } from 'react-redux';
import { selectCount, selectIsFetching, selectModalBulkUpdateValue, selectSelectAllVulns, selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import { bulkUpdateVulnsFromAsset } from 'store/Contextualization/AssetDetail/actions';
import SelectAll from '../SelectAll';

const VulnsTable = () => {
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const vulnsCount = useSelector((state) => selectCount('vulns', state));
  const isSelectAll = useSelector(selectSelectAllVulns);
  const isFetching = useSelector((state) => selectIsFetching('vulns', state));
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);

  return (
    <Wrapper>
      <SelectAll />
      <BulkUpdateModal />
      <BulkUpdateConfirmationDialog
        selected={ vulnsSelected }
        count={ vulnsCount }
        isSelectAll={ isSelectAll }
        isFetching={ isFetching }
        bulkUpdateValue={ bulkUpdateValue }
        bulkUpdateAction={ bulkUpdateVulnsFromAsset }
        title="Vulnerabilities"
      />
      <Table />
    </Wrapper>
  );
};

export default VulnsTable;
