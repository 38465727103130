import styled from 'styled-components';
import colors from 'Styles/colors';
import { FiTrash2 as Trash } from 'react-icons/fi';
import { ellipsisText } from 'Styles/styles';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const DataContainer = styled.div`
    width: ${(props) => (props.responsive ? '100%' : '400px')};      
    margin-top: ${(props) => (props.mtList ? props.mtList : '21px')};
    background-color: ${colors.white};
    box-shadow: 0 2px 50px 0 ${colors.iceBlue};
    max-height: 117px;
    overflow-y: auto;
`;
DataContainer.displayName = 'DataContainer';

export const DataItem = styled.div`    
    font-size: 13px;
    color: #1c2243;
    cursor: pointer;
    padding: 7px 5px 7px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${(props) => {
    if (props.responsive) { return '100%;'; }
    if (props.width) { return `${props.width};`; }
    return '400px';
  }};      
    background-color: ${(props) => (props.isOdd ? `${colors.iceBlue}` : '')};
`;
DataItem.displayName = 'DataItem';

export const Text = styled.div`
  text-align: left;
  ${ellipsisText}
`;
Text.displayName = 'Text';

export const Span = styled.span`
  position: ${(props) => (props.responsive ? 'absolute' : 'relative')};
  top: ${(props) => {
    if (props.responsive) {
      return props.hasTitle ? '39px' : '9px';
    }
    return '';
  }};
  right: ${(props) => (props.responsive ? '15px' : '')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
Span.displayName = 'Span';

export const TrashIcon = styled(Trash)` 
    width: 16px;
    height: 17px;
    align-self: flex-end;
    color: ${colors.grey12};
`;

TrashIcon.displayName = 'TrashIcon';

export const Input = styled.input`
  height: ${(props) => (props.height)};
`;
Input.displayName = 'Input';
