import React from 'react';
import { useDispatch } from 'react-redux';
import FlagIcon from 'Common/Components/FlagIcon';
import { updateVuln } from 'store/Manage/actions';
import { updateVulnFromAsset } from 'store/Contextualization/AssetDetail/actions';

export const Confirm = ({ vuln, onAsset = false }) => { // eslint-disable-line import/prefer-default-export
  const dispatch = useDispatch();
  const confirm = (value) => {
    if (onAsset) {
      dispatch(updateVulnFromAsset(vuln, 'confirmed', value));
    } else {
      dispatch(updateVuln(vuln, 'confirmed', value));
    }
  };

  return (vuln.status && vuln.name && vuln.confirmed
    ? (
      <FlagIcon
        theme="confirmed"
        alt="Confirmed"
        title="Confirmed"
        onClick={ () => confirm(false) }
        aria-label="Confirmed"
      />
      )
    : (
      <FlagIcon
        theme="notConfirmed"
        alt="Not-Confirmed"
        title="Not Confirmed"
        onClick={ () => { confirm(true); } }
        aria-label="Not Confirmed"
      />
      )
  );
};
