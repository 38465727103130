import fetchApi from 'services/api/connector';

export const createTemplate = (data) => fetchApi('vulnerability_template', { method: 'POST', data });

export const updateTemplate = (id, data) => fetchApi(`vulnerability_template/${id}`, { method: 'PATCH', data });

export const fetchTemplates = (queryParam) => fetchApi(`vulnerability_template/filter?q=${escape(JSON.stringify(queryParam))}`);

export const deleteTemplates = (data) => fetchApi('vulnerability_template', { method: 'DELETE', data });

export const fetchTemplateById = (id) => fetchApi(`vulnerability_template/${id}`);
