import React from 'react';
import { PropTypes } from 'prop-types';
import { selectIsFetchingVulnDetails, selectVulnDetailSelectedTabContext } from 'store/Manage/selectors';
import { useSelector } from 'react-redux';
import TabWrapper from './styled';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

const Tabs = ({ onScroll, tabRef }) => {
  const selectedTab = useSelector(selectVulnDetailSelectedTabContext);
  const isFetching = useSelector(selectIsFetchingVulnDetails);

  return (
    <TabWrapper ref={ tabRef } onScroll={ onScroll }>
      {isFetching ? <SpinnerComponent /> : selectedTab.component }
    </TabWrapper>
  );
};

Tabs.propTypes = {
  onScroll: PropTypes.func.isRequired,
  tabRef: PropTypes.object.isRequired
};

export default Tabs;
