import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getServices, selectService } from 'store/HostDetail/actions';
import {
  selectIsFetchingServices, selectServices, selectServicesFields
} from 'store/HostDetail/selectors';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import { FormattedMessage, useIntl } from 'react-intl';
import setOrderServices from 'Screens/Host/components/HostDetail/functions';
import { openModal } from 'store/modals/actions';
import { MODAL_CREATE_SERVICE } from 'store/modals/modals';
import getColumns from './components/Columns';
import { Wrapper, AddServiceButton } from './styled';
import getTrProps from './getTrProps';

const WrapperTable = ({ onSelectService }) => {
  const isFetching = useSelector(selectIsFetchingServices);
  const data = useSelector(selectServices);
  const fields = useSelector(selectServicesFields);

  const customStyle = { height: 'calc(100vh - 375px)', cursor: 'pointer' };

  const fetchData = (state) => {
    const { sorted } = state;

    if (!isFetching) {
      setOrderServices(sorted, data);
    }
  };

  const mockData = [...Array(15).keys()];

  return (
    <CustomReactTable
      data={ isFetching ? mockData : data }
      columns={ getColumns(fields, isFetching) }
      minRows={ 0 }
      showPagination={ false }
      noDataText={ !isFetching && <FormattedMessage id="app.nodata.context" /> }
      getTrProps={ (state, rowInfo) => getTrProps(state, rowInfo, onSelectService) }
      style={ customStyle }
      onFetchData={ fetchData }
    />
  );
};

WrapperTable.propTypes = {
  onSelectService: PropTypes.func.isRequired
};

const Services = ({ data }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServices(data.id));
  }, [data, dispatch]);

  const onSelectService = (service) => {
    dispatch(selectService(service));
    dispatch(openModal(MODAL_CREATE_SERVICE));
  };

  return (
    <Wrapper>
      <WrapperTable onSelectService={ onSelectService } />
      <AddServiceButton text={ intl.formatMessage({ id: 'host.detail.services.addEditService' }) } onClick={ () => dispatch(openModal(MODAL_CREATE_SERVICE)) } />
    </Wrapper>
  );
};

export default Services;
