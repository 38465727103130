import styled from 'styled-components';
import colors from 'Styles/colors';

export const StyledTitle = styled.h2`
  font-size: 21.5px;
  font-weight: 500;
  color: ${colors.darkBlueGrey};
  margin-bottom: 0;
  margin-right: auto;
`;
StyledTitle.displayName = 'StyledTitle';

export const TitleWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 25px; 
  flex-direction: column;
  align-items: flex-start;
`;
TitleWrapper.displayName = 'TitleWrapper';

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;
SubtitleWrapper.displayName = 'SubtitleWrapper';

export const Subtitle = styled.h3`
  color: ${colors.darkBlueGrey};
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
`;
Subtitle.displayName = 'Subtitle';

export const Description = styled.h4`
  color: ${colors.dark5};
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
  margin-left: 5px;
`;
Description.displayName = 'Description';
