import React, { useState } from 'react';
import Input from './styled';
import 'react-datepicker/dist/react-datepicker.css';

const DateInput = ({ onValueSelect }) => {
  const [startDate, setStartDate] = useState(new Date());

  const onChange = (date) => {
    setStartDate(date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    onValueSelect(dateString);
  };
  return (
    <Input open selected={ startDate } onSelect={ date => onChange(date) } dateFormat="yyyy/MM/dd" />
  );
};

export default DateInput;
