import React from 'react';
import { useSelector } from 'react-redux';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import HostServices from 'Screens/Contextualization/Host/components/HostServices';

const ServicesAssetWrapper = ({ params }) => {
  const showLegacyUI = useSelector(selectShowLegacyUI);

  if (showLegacyUI) return null;
  return <HostServices params={ params } />;
};

export default ServicesAssetWrapper;
