import styled from 'styled-components';
import colors from 'Styles/colors';

export const StyledMessage = styled.div`
  color: ${colors.grey19};
  font-size: 12.5px;
  display: flex;
`;
StyledMessage.displayName = 'StyledMessage';

export const Asterisk = styled.div`
  &:after {
    content: '*';
    color: ${colors.redPink};
    font-size: 16px;
  }
`;
Asterisk.displayName = 'Asterisk';
