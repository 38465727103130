import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-top: 25px;
  > :not(:last-child) {
     margin-bottom: 21px;
  }
`;

Wrapper.displayName = 'Wrapper';

export const NoFieldsText = styled.span`
  font-size: 12.5px;
  line-height: 1.76;
  color: ${colors.grey19};
  user-select: none;
`;

NoFieldsText.displayName = 'NoFieldsText';
