import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { autoSelectVulnItem, selectVulnRow } from 'store/Contextualization/AssetDetail/actions';
import { selectIsFetching, selectSelectAllVulns, selectSelected, selectUnselectedRowFromSelectAll } from 'store/Contextualization/AssetDetail/selectors';
import Checkbox from 'Common/Components/Checkbox';
import Skeleton from 'Common/Components/Skeleton';

export const CheckColumVulnAsset = ({ vuln, showSkeleton }) => { // eslint-disable-line import/prefer-default-export
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const dispatch = useDispatch();
  const isSelected = vulnsSelected.find((vulnSelected) => vulnSelected._id === vuln._id);
  const handleClick = (e) => dispatch(selectVulnRow(e, vuln));
  const selectAll = useSelector(selectSelectAllVulns);
  const loading = useSelector((state) => selectIsFetching('vulns', state));
  const unselectedRowFromSelectAll = useSelector(selectUnselectedRowFromSelectAll);

  useEffect(() => {
    if (!loading && selectAll && !isSelected && !unselectedRowFromSelectAll) {
      dispatch(autoSelectVulnItem(vuln));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  if (showSkeleton) return <Skeleton />;
  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
