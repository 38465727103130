import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const CloseIcon = styled(CloseSVG)`
  position: absolute;
  left: 9px;
  top: 9px;
  margin: auto;
  cursor: pointer;
  &>:hover{
    left: 8px;
    top: 8px;
    & g {
      & circle{
        background-color: ${colors.iceBlue};
      }  
    }
  }  
  & g {
    & path{
      fill: ${colors.grey19};
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const CloseIconWrapper = styled.div`
  flex: 0 0 37px;
  position: relative;
  padding-left: 3px;
  height: 37px;
  cursor: pointer;
  &:hover{
    background-color: ${colors.iceBlue};
    border-radius: 50%;
  }  
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';

export const Owned = styled.div`
  height: 25px;
  padding: 3px 9px 5px 8px;
  border-radius: 3px;
  align-self: center;
  font-size: 13px;
  font-weight: 600;
  color: ${colors.white};
  background-color: ${(props) => (props.owned ? '#e53056' : '#85c83e')};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  &:hover {
    opacity: 0.8;
  }
`;
Owned.displayName = 'Owned';
