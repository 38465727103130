import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allWorkspacesAreSelected, someWsIsSelected } from 'store/Workspace/selectors';
import { selectAllWorkspaces } from 'Screens/Contextualization/Workspaces/actions/Actions';
import Checkbox from 'Common/Components/Checkbox';

const CheckHeader = () => {
  const allWorkspacesSelected = useSelector(allWorkspacesAreSelected);
  const someWsSelected = useSelector(someWsIsSelected);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(selectAllWorkspaces());
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allWorkspacesSelected } partialChecked={ someWsSelected && !allWorkspacesSelected } />
  );
};

export default CheckHeader;
