import React from 'react';
import { Input } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/CVE';
import { Title } from './styled';

const CVE = ({ listData, onAdd, onRemove }) => {
  return (
      <div style={ { width: '410px' } }>
          <Title>CVE</Title>
        <Input
          placeholder="CVE-YYYY-NNNN"
          errorMessage="CVE not valid"
          validator={ /^(CVE-(1999|2\d{3})-(\d{4,7}))$/ }
          listData={ listData }
          onAdd= { onAdd }
          onRemove={ onRemove }
        />
      </div>
  );
};

export default CVE;
