import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StringField from 'Common/Components/StringField';
import {
  setField,
  addPolicy,
  removePolicy,
  addCVE,
  removeCVE
} from 'Screens/KnowledgeBaseEditCreate/actions/Actions';
import {
  selectTemplateEditCreate,
  selectPermissions,
  selectReadonly
} from 'store/KnowledgeBase/selectors';
import SeverityDropdown from 'Common/Components/SeverityDropdown';
import EORDropdown from 'Common/Components/EORDropdown';
import CustomList from 'Common/Components/CustomList';
import Impacts from 'Common/Components/Impacts';
import {
  Wrapper, DropdownsContainer, RedDot, Black, Row, PolicyColumn, Box
} from './styled';
import MarkdownInput from 'Screens/ManageEditCreate/components/ManualCreationModal/components/MarkdownInput';
import CVE from './InputCVE';

const General = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => ({ ...selectReadonly(state), ...selectPermissions(state), ...selectTemplateEditCreate(state) }));

  const saveData = (field, value) => {
    dispatch(setField(field, value));
  };

  const setCVE = (val) => {
    dispatch(addCVE(val));
  };
  const removeCVEFromKB = (val) => {
    dispatch(removeCVE(val));
  };

  const impacts = {
    accountability: props.accountability,
    availability: props.availability,
    confidentiality: props.confidentiality,
    integrity: props.integrity
  };

  return (
    <Wrapper>
      <Row className="ml-0 mr-0">
        <StringField
          className="mr-17"
          width="405px"
          height="38px"
          placeholder="Vulnerability template name"
          defaultValue={ props.name }
          onChange={ (value) => saveData('name', value) }
          title={ (
            <RedDot>
              <Black>Name</Black>
              {' '}
              *
            </RedDot>
) }
        />
        <DropdownsContainer>
          <SeverityDropdown updateValue={ (field, value) => saveData('exploitation', value) } defaultValue={ props.exploitation } />
          <EORDropdown updateValue={ (field, value) => saveData('easeofresolution', value) } defaultValue={ props.easeofresolution } />
        </DropdownsContainer>
      </Row>

      <Row className="ml-0 mr-0 margin-top-26">
        <Box>
          <MarkdownInput
            title="Description"
            value={ props.description }
            onBlur={ (value) => saveData('description', value) }
            minHeight={ 130 }
          />
        </Box>
        <Box>
          <MarkdownInput
            title="Resolution"
            value={ props.resolution }
            onBlur={ (value) => saveData('resolution', value) }
          />
        </Box>
      </Row>

      <Row className="ml-0 mr-0 margin-top-26">
        <PolicyColumn>
          <CustomList
            key="CL_POLICY_VIOLATIONS"
            title="Policy Violations"
            placeholder="Add a Policy Violation"
            addItem={ () => dispatch(addPolicy()) }
            removeItem={ (index) => dispatch(removePolicy(index)) }
            setItem={ (value) => saveData('policy', value) }
            listData={ props.policyviolations }
            responsive
            canUpdate
          />
        </PolicyColumn>
        <>
          <Impacts
            selectCheckbox={ (field) => saveData(field, !props[field]) }
            impacts={ impacts }
            margin={ false }
          />
        </>
      </Row>
      <Row className="ml-0 mr-0 margin-top-26">
      <>
          <CVE
            listData={ props.cve }
            onAdd= { setCVE }
            onRemove={ removeCVEFromKB }
          />
      </>
      </Row>

    </Wrapper>
  );
};

export default withRouter(General);
