import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectModalBulkUpdateField, selectModalBulkUpdateValue } from 'store/Host/selectors';
import { setBulkUpdateValue } from 'store/Host/actions';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';

const BulkMarkdownEditor = () => {
  const dispatch = useDispatch();
  const bulkUpdateField = useSelector(selectModalBulkUpdateField);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);

  return (
    <MarkdownEditor
      title={ bulkUpdateField }
      onBlur={ (value) => dispatch(setBulkUpdateValue(value)) }
      value={ bulkUpdateValue }
    />
  );
};

export default BulkMarkdownEditor;
