import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  overflow: scroll;
  height: 100%;
  justify-content: center;
`;
Wrapper.displayName = 'Wrapper';

export const View = styled.div`
  position: relative;
  height: 94%;
`;
View.displayName = 'View';
