import styled from 'styled-components';

export const NameWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
NameWrapper.displayName = 'NameWrapper';

export const Name = styled.div`
  font-weight: 600;
  color: #1c4566;
  font-size: 14.5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;
Name.displayName = 'Name';
