import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectTemplateDetailSelectedTab } from 'store/KnowledgeBase/selectors';
import TabWrapper from './styled';

const Tabs = ({ onScroll, tabRef }) => {
  const selectedTab = useSelector(selectTemplateDetailSelectedTab);

  return (
    <TabWrapper ref={ tabRef } onScroll={ onScroll }>
      { selectedTab.component }
    </TabWrapper>
  );
};

Tabs.propTypes = {
  onScroll: PropTypes.func.isRequired,
  tabRef: PropTypes.object.isRequired
};

export default Tabs;
