import React from 'react';
import { StyledTitle } from '../styled';

export const KbTitle = () => (
  <StyledTitle>Vulnerability Knowledge Base</StyledTitle>
);

export const Agents = () => (
  <StyledTitle>Agents</StyledTitle>
);
