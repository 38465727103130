import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import DragFile from 'Common/Components/DragFile';
import { setFileIndex, setUploadState } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { Wrapper, Click, Separator } from './styled';
import AdvancedOptions from '../AdvancedOptions';

const DragStep = ({
  setStep,
  files, setFiles
}) => {
  const dispatch = useDispatch();

  const handleDrop = (filesUploading) => {
    const fileList = files;
    for (let i = 0; i < filesUploading.length; i += 1) {
      if (!filesUploading[i].name) return;
      if (fileList.findIndex((item) => (item.name === filesUploading[i].name && item.size === filesUploading[i].size)) === -1) {
        fileList.push(filesUploading[i]);
      }
    }
    setFiles([...fileList]);
    if (fileList.length > 0) setStep('LIST');
    dispatch(setFileIndex(fileList.length - 1));
    dispatch(setUploadState());
  };

  return (
    <Wrapper>
      <DragFile onManage handleDrop={ handleDrop } />
      <Click href="https://docs.faradaysec.com/import/" target="_black" children={ <FormattedMessage id="importFileCreationModal.info" /> } />
      <Separator />
      <AdvancedOptions />
    </Wrapper>
  );
};

export default DragStep;
