import React from 'react';
import Clipboard from 'Screens/Contextualization/Dashboard/components/ToolbarItems/Card/components/Clipboard';
import { Wrapper, Text } from './styled';

const Target = ({ children, title }) => {
  return (
    <Wrapper>
      <Text title={ title }>{children}</Text>
      <Clipboard text={ title } />
    </Wrapper>
  );
};

export default Target;
