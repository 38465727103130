import React from 'react';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import copyToClipboard from 'utils/clipboard';
import { CopyLinkWrapper, CopyLinkIcon } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Title/components/CopyLink/styled';

const CopyLink = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.detail.tab.copyLink' });
  const link = get(window, 'location.href', '');
  const copyLink = () => { if (link) copyToClipboard(link); };
  return (
    <CopyLinkWrapper title={ title } onClick={ copyLink }>
      <CopyLinkIcon />
    </CopyLinkWrapper>
  );
};

export default CopyLink;
