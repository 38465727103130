import React from 'react';
import BulkMarkdownEditor from './BulkMarkdownEditor';
import ItemList from './ItemList';
import StringInput from './StringInput';

const BULK_UPDATE_COMPONENTS = {
  hostnames: <ItemList />,
  os: <StringInput name="Operating System" />,
  mac: <StringInput name="MAC Address" />,
  description: <BulkMarkdownEditor />
};

export default BULK_UPDATE_COMPONENTS;
