import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: right;
  margin: 0px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  height: 37px;
  margin-bottom: 9px;
`;
Wrapper.displayName = 'Wrapper';

export const Separator = styled.div`
  &::after {
    margin: auto 0px;
    width: 1px;
    content: ' ';
    background: transparent;
    border-right: 1px #B5BEC6;
    border-right-style: dashed;
    display: block; 
    height: 11px;
  }
`;
Separator.displayName = 'Separator';

export const Group = styled.div`
  display: flex;
  align-items: center;
`;
Group.displayName = 'Group';

export const LeftGroup = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  
  & > *:not(:last-child) {
      margin-right: 15px;
  }
`;
LeftGroup.displayName = 'LeftGroup';

export const RightGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  & > * {
      margin-right: 15px;
  }
`;
RightGroup.displayName = 'RightGroup';
