import React from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from 'Common/Components/Skeleton';
import { redirectToVulnDetail, selectRow, unSelectAll } from 'store/Manage/actions';
import { Wrapper, Text } from './styled';

// eslint-disable-next-line import/prefer-default-export
export const Name = ({
  showSkeleton, groupBy, isPivot, vuln
}) => {
  const dispatch = useDispatch();
  const handleClick = (e) => {
    dispatch(unSelectAll());
    dispatch(selectRow(e, vuln));
    dispatch(redirectToVulnDetail(vuln._id));
  };

  if (showSkeleton) return <Skeleton />;
  if (groupBy && isPivot) return null;
  return (
    <Wrapper>
      <Text confirmed={ vuln.confirmed } onClick={ handleClick }>{ vuln.name }</Text>
    </Wrapper>
  );
};
