import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import Wrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExternalId/styled';

const TAB = 'general';
const ID = 'externalID';

const ExternalId = () => {
  const intl = useIntl();
  const currentVuln = useSelector((state) => selectDetail('vulns', state));
  const externalId = get(currentVuln, 'external_id', '');
  const isExpanded = useExpandable(TAB, ID, externalId);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.externalID' }) } tab={ TAB } id={ ID }>
      <Wrapper>
      { externalId || '' }
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default ExternalId;
