import styled from 'styled-components';
import colors from 'Styles/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: ${colors.white};
  padding-top: 8px;
  position: relative;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
