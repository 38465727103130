import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  flex: 1 1 0px;
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
