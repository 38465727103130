import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accept, Cancel } from 'Common/Components/Button/styled';
import {
  selectHostsSelected, selectModalBulkUpdateField,
  selectModalBulkUpdateValue
} from 'store/Host/selectors';
import { MODAL_MANAGE_BULK_UPDATE, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import { bulkUpdateAssets } from 'store/Host/actions';
import { useIntl } from 'react-intl';
import ButtonsContainer from './styled';

const SubmitButtons = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const cancelLabel = intl.formatMessage({ id: 'common.components.button.cancel' });
  const saveLabel = intl.formatMessage({ id: 'common.components.button.save' });

  const bulkUpdateField = useSelector(selectModalBulkUpdateField);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);

  const vulnsSelected = useSelector(selectHostsSelected);
  const selectedVulnsCount = vulnsSelected.length;

  const handleClose = () => dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedVulnsCount > 1) dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    else dispatch(bulkUpdateAssets(bulkUpdateValue));
  };

  return (
    <ButtonsContainer>
      <Cancel onClick={ handleClose } children={ cancelLabel } />
      <Accept onClick={ handleSubmit } children={ saveLabel } disabled={ bulkUpdateField === 'name' && !bulkUpdateValue } />
    </ButtonsContainer>

  );
};

export default SubmitButtons;
