import React from 'react';
import { useSelector } from 'react-redux';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import Services from 'Screens/Contextualization/Services';

const ServicesWrapper = ({ params }) => {
  const showLegacyUI = useSelector(selectShowLegacyUI);

  if (showLegacyUI) return null;
  return <Services params={ params } />;
};

export default ServicesWrapper;
