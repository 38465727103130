import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssetRow } from 'store/Host/actions';
import Checkbox from 'Common/Components/Checkbox';
import { selectHostsSelected } from 'store/Host/selectors';

export const CheckColum = ({ host }) => { // eslint-disable-line import/prefer-default-export
  const hostsSelected = useSelector((state) => selectHostsSelected(state));
  const dispatch = useDispatch();
  const isSelected = hostsSelected.find((hostSelected) => hostSelected._id === host._id);
  const handleClick = (e) => dispatch(selectAssetRow(e, host));
  return (
    <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
  );
};
