const defaultFields = {
  exploitation: {
    visible: true, order: 1, locked: true, maxWidth: 45, minWidth: 45, defaultMin: 45
  },
  name: {
    visible: true, order: 2, locked: true, maxWidth: 400, minWidth: 200, defaultMin: 200
  },
  description: {
    visible: true, order: 3, maxWidth: 400, minWidth: 150, defaultMin: 150
  },
  references: {
    visible: true, order: 4, maxWidth: 350, minWidth: 175, defaultMin: 175
  },
  resolution: {
    visible: true, order: 5, maxWidth: 350, minWidth: 175, defaultMin: 175
  },
  policyviolations: {
    visible: true, order: 6, maxWidth: 350, minWidth: 175, defaultMin: 175
  },
  creator_id: {
    visible: true, order: 7, maxWidth: 100, minWidth: 100, defaultMin: 100
  },
  create_date: {
    visible: true, order: 8, maxWidth: 120, minWidth: 120, defaultMin: 120
  }
};

export default defaultFields;
