import React, { useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { Wrapper, InputTitle, IconHost } from './styled';
import { selectReadOnly } from 'store/Faraday/selectors';

const Title = ({ serviceDetail, serviceId, updateService }) => {
  const [serviceName, setServiceName] = useState(serviceDetail.name);
  const readOnly = useSelector(selectReadOnly);
  const disabled = readOnly;

  useEffect(() => {
    setServiceName(serviceDetail.name);
  }, [serviceDetail]);

  const updateName = useRef(debounce((name) => {
    if (name) {
      updateService(serviceId, { name });
    }
  }, 1000));

  const handleChange = (e) => {
    const name = e.target.value;
    setServiceName(name);
    updateName.current(name);
  };

  return (
    <Wrapper>
      <IconHost />
      <InputTitle value={ serviceName } onChange={ handleChange } disabled={ disabled } />
    </Wrapper>
  );
};

export default Title;
