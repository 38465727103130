import React from 'react';
import { getRequestUrl } from 'services/api/connector';
import { useIntl } from 'react-intl';
import copyToClipboard from 'utils/clipboard';
import { CopyApiLinkWrapper, CopyApiLinkIcon } from './styled';

const CopyApiLink = ({ path }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.detail.tab.copyApiLink' });

  const copyApiLink = () => {
    const link = getRequestUrl(path);
    copyToClipboard(link);
  };

  return (
    <CopyApiLinkWrapper title={ title } onClick={ copyApiLink }>
      <CopyApiLinkIcon />
    </CopyApiLinkWrapper>
  );
};

export default CopyApiLink;
