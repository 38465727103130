import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 23px;
`;
Wrapper.displayName = 'Wrapper';

export const CloseIcon = styled(CloseSVG)`
  position: absolute;
  left: 9px;
  top: 9px;
  margin: auto;
  cursor: pointer;
  &>:hover{
    left: 8px;
    top: 8px;
    & g {
      & circle{
        background-color: ${colors.iceBlue};
      }  
    }
  }  
  & g {
    & path{
      fill: ${colors.grey19};
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const CloseIconWrapper = styled.div`
  flex: 0 0 37px;
  position: relative;
  padding-left: 3px;
  height: 37px;
  cursor: pointer;
  &:hover{
    background-color: ${colors.iceBlue};
    border-radius: 50%;
  }  
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';
