import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllVulns, unSelectAll, unselectTotalItems, unselectedRowFromSelectAll } from 'store/Contextualization/AssetDetail/actions';
import { selectSelected, selectVulns, someVulnIsSelected } from 'store/Contextualization/AssetDetail/selectors';
import Checkbox from 'Common/Components/Checkbox';

export const CheckHeaderVulnAsset = () => { // eslint-disable-line import/prefer-default-export
  const vulns = useSelector(selectVulns);
  const selectedVulns = useSelector((state) => selectSelected('vulns', state));
  const someVulnSelected = useSelector(someVulnIsSelected);
  const dispatch = useDispatch();

  const allVulnsSelected = someVulnSelected && vulns.length === selectedVulns.length;

  const handleClick = () => {
    if (allVulnsSelected) {
      dispatch(unSelectAll('vulns'));
      dispatch(unselectedRowFromSelectAll(true, 'vulns'));
      dispatch(unselectTotalItems('vulns'));
    } else {
      dispatch(selectAllVulns());
    }
  };

  return (
    <Checkbox theme="grey" onChange={ handleClick } state={ allVulnsSelected } partialChecked={ someVulnSelected && !allVulnsSelected } />
  );
};
