import React from 'react';
import { useSelector } from 'react-redux';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import KnowledgeBaseContext from 'Screens/Contextualization/KnowledgeBase/components';
import KnowledgeBase from 'Screens/KnowledgeBase/components';

const KnowledgeBaseWrapper = ({ params }) => {
  const showLegacyUI = useSelector(selectShowLegacyUI);

  return (
    <>
      {
        showLegacyUI
          ? <KnowledgeBase />
          : <KnowledgeBaseContext params={ params } />
      }
    </>
  );
};

export default KnowledgeBaseWrapper;
