import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  selectFileIndex, selectIgnoreInfo, selectResolveHost
} from 'store/ManageEditCreate/selectors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { uploadFile, setFileIndex, setUploadState, resetFileUploadState, setFilesCount } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { Button, ButtonsContainer } from 'Common/Components/MainContainer/styled';
import { Wrapper, LeftTitle, BackBtn } from './styled';
import DragStep from './components/DragStep';
import ListStep from './components/ListStep';
import { showProcessingQueue } from 'Common/Components/FileUploadContext/actions';

const ImportFileCreationModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState('DRAG');
  const ignoreInfo = useSelector(selectIgnoreInfo);
  const resolveHost = useSelector(selectResolveHost);
  const fileIndex = useSelector(selectFileIndex);

  const titleLabel = intl.formatMessage({ id: 'importFileCreationModal.title' });
  const doneLabel = intl.formatMessage({ id: 'importFileCreationModal.doneButton' });
  const uploadLabel = intl.formatMessage({ id: 'importFileCreationModal.uploadButton' });
  const cancelLabel = intl.formatMessage({ id: 'importFileCreationModal.cancelButton' });

  const onHandleClose = () => {
    setFiles([]);
    setStep('DRAG');
    dispatch(resetFileUploadState());
    handleClose();
  };

  const handleDrop = (filesUploading) => {
    const fileList = files;
    for (let i = 0; i < filesUploading.length; i += 1) {
      if (!filesUploading[i].name) return;
      if (fileList.findIndex((item) => (item.name === filesUploading[i].name && item.size === filesUploading[i].size)) === -1) {
        fileList.push(filesUploading[i]);
      }
    }
    setFiles([...fileList]);
    if (fileList.length > 0) setStep('LIST');
    dispatch(setFileIndex(fileList.length - 1));
    dispatch(setUploadState());
  };

  const upload = () => {
    const params = [
      { name: 'file', value: files[fileIndex] },
      { name: 'ignore_info', value: ignoreInfo },
      { name: 'resolve_hostname', value: resolveHost }
    ];
    setStep('UPLOAD');
    dispatch(setFilesCount(files));
    if (fileIndex >= 0) dispatch(uploadFile(params));
    dispatch(showProcessingQueue(true));
    onHandleClose();
  };

  const stepComponent = {
    LIST: <ListStep
      files={ files }
      setFiles={ setFiles }
      setStep={ setStep }
      handleDrop={ handleDrop }
    />,
    DRAG: <DragStep
      files={ files }
      setFiles={ setFiles }
      setStep={ setStep }
    />
  };

  if (!show) return null;
  return (
    <ModalWrapper>
      <Wrapper>
        <LeftTitle children={ titleLabel } />
        { stepComponent[step] }
        <ButtonsContainer>
          { step === 'SUMMARY'
            ? <Button onClick={ onHandleClose } children={ doneLabel } />
            : <Button onClick={ upload } disabled={ step !== 'LIST' || isEmpty(files) } children={ uploadLabel } />
          }
          <BackBtn onClick={ onHandleClose } children={ cancelLabel } />
        </ButtonsContainer>
      </Wrapper>
    </ModalWrapper>
  );
};

ImportFileCreationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ImportFileCreationModal;
