import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Title, ChildWrapper,
  Header, ExpandedIcon, CollapsedIcon, Counter,
  WrapperButtons
} from './styled';
import { useDispatch } from 'react-redux';
import { toggleExpandCollapse } from 'store/Global/actions';

const ExpandableWrapper = ({
  children, title, count, defaultValue, tab, id, buttons
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultValue);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsExpanded(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
    dispatch(toggleExpandCollapse(tab, {
      [id]: {
        expanded: !isExpanded
      }
    }));
  };

  const showCount = count >= 0;

  return (
    <Wrapper>
      { isExpanded && buttons ? <WrapperButtons children={ buttons } /> : null }
      <Header onClick={ handleClick }>
        <Title children={ title } />
        <Counter show={ showCount } children={ count } />
        { isExpanded ? <ExpandedIcon /> : <CollapsedIcon /> }
      </Header>
      <ChildWrapper $isExpanded={ isExpanded } children={ children } />
    </Wrapper>
  );
};

ExpandableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  count: PropTypes.number,
  defaultValue: PropTypes.bool,
  id: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired
};

ExpandableWrapper.defaultProps = {
  title: '',
  count: -1,
  defaultValue: false
};

export default React.memo(ExpandableWrapper);
