import React, { useState } from 'react';
import ActionBar from './components/ActionBar';
import { MODAL_UPGRADE_LICENSE_ACTION_BAR } from 'store/modals/modals';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';

const CustomActionBar = () => {
  const [FFImage, setFFImage] = useState(null);
  const [FFDescription, setFFDescription] = useState('');

  const setFF = (image, description) => {
    setFFImage(image);
    setFFDescription(description);
  };

  return (
    <>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE_ACTION_BAR }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_ACTION_BAR } image={ FFImage } descriptionIntlId={ FFDescription } />
      </ReduxModal>
      <ActionBar setFF={ setFF } entity="vulns" />
    </>
  );
};

export default CustomActionBar;
