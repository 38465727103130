import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  selectTemplateEditCreate
} from 'store/KnowledgeBase/selectors';
import {
  addReference,
  removeReference,
  setField
} from 'Screens/KnowledgeBaseEditCreate/actions/Actions';

import Md from 'Common/Components/Md';
import StringField from 'Common/Components/StringField';
import CustomList from 'Common/Components/CustomList';
import {
  Wrapper, ListWraper, Row, Data
} from './styled';

const TechnicalDetails = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => ({ ...selectTemplateEditCreate(state) }));

  const saveData = (value) => {
    dispatch(setField('data', value));
  };

  return (
    <Wrapper>
      <Row className="ml-0">
        <Md title="Data" saveAction={ saveData } size="lg" value={ props.data } />
        <Data>
          <StringField className="mr-17" width="400px" height="38px" placeholder="125798926" title="External ID" value={ props.external_id } onChange={ (value) => dispatch(setField('external_id', value)) } defaultValue={ props.external_id } />
          <ListWraper>
            <CustomList
              key="CL_REFERENCES"
              title="References"
              placeholder="Add a Reference"
              addItem={ () => dispatch(addReference()) }
              removeItem={ (index) => dispatch(removeReference(index)) }
              setItem={ (value) => dispatch(setField('reference', value)) }
              listData={ props.references }
              canUpdate
            />
          </ListWraper>
        </Data>
      </Row>
    </Wrapper>
  );
};

export default withRouter(TechnicalDetails);
