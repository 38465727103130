import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ShortLogo } from 'Images/faraday-logo-nav.svg';
import CheckBox from 'Common/Components/Checkbox/styled';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div` 
  user-select: none;
  background-color: #f4f8fa;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Box = styled.div`
  width: 375px;
  margin-left: auto;
  margin-right: auto;
  padding: 39px 34px 25px 33px;
  border-radius: 2px;
  box-shadow: 0 2px 50px 0 rgba(28, 34, 67, 0.15);
  border: solid 1px ${colors.iceBlue};
  background-color: ${colors.paleGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
Box.displayName = 'Box';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: ${colors.darkBlueGrey};
  margin: 30px 0 13px 0;
  line-height: normal;
`;
Title.displayName = 'Title';

export const Subtitle = styled.span`
  font-size: 13px;
  color: #63758d;
  line-height: 1.38;

`;
Subtitle.displayName = 'Subtitle';

export const VersionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 42px 0 27px 0;
  width: 100%;
  padding: 0 40px;
`;
VersionWrapper.displayName = 'VersionWrapper';

export const Version = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: ${colors.darkBlueGrey};
`;
Version.displayName = 'Version';

export const Label = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.grey19};
`;
Label.displayName = 'Label';

export const Logo = styled(ShortLogo)`
`;
Logo.displayName = 'Logo';

export const Toggle = styled(CheckBox)`
  margin: 0 27px 0 19px;

`;
Toggle.displayName = 'Toggle';

export const Button = styled(BlueButton)`
  margin: 15px 0 5px 0;

`;
Button.displayName = 'Button';
