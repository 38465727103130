import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modals/actions';
import { MODAL_WORKSPACE_CREATE } from 'store/modals/modals';
import { AddWsButton } from '../../../styled';

// eslint-disable-next-line import/prefer-default-export
export const WsAddButton = () => {
  const dispatch = useDispatch();
  return <AddWsButton text="Add Workspace" onClick={ () => dispatch(openModal(MODAL_WORKSPACE_CREATE)) } />;
};
