import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  
  ${({ showSeparator }) => showSeparator && `
    &::after {
      width: 1px;
      content: ' ';
      background: transparent;
      border-right: 1px #B5BEC6;
      border-right-style: dashed;
      height: 14px;
      margin: 0px 8px 0px 18px;
    }
  `}
`;
Wrapper.displayName = 'Wrapper';

export const Button = styled.div`
  padding: 0px 10px;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(144, 169, 192, 0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    height: 11px;
    margin-right: 10px;
     & > path {
      fill: ${colors.dark2};
    }
  }
  &:hover {
        border-radius: 3px;
        border: solid 1px #d8dee5;
        background-color: #f1f4f7;
      }

      &:active {
        border: solid 1px #bcccd9;
        background-color: #dde5ec;
      }
  
`;
