import React from 'react';
import SeverityPicker from './components/SeverityPicker';
import EaseOfResolutionPicker from './components/EaseOfResolutionPicker';
import Wrapper from './styled';

const ActionBar = () => (
  <Wrapper>
    <SeverityPicker />
    <EaseOfResolutionPicker />
  </Wrapper>
);
export default ActionBar;
