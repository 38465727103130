import React from 'react';
import get from 'lodash/get';
import { Wrapper, StyledMd } from './styled';

const Description = ({ ws }) => {
  const description = get(ws, 'description', '');
  return (
    <Wrapper>
      <StyledMd>
        {description}
      </StyledMd>
    </Wrapper>
  );
};

export default Description;
