import styled from 'styled-components';
import { ReactComponent as ListViewImage } from 'Images/list_view.svg';
import { disableTextSelection } from 'Styles/effects/text';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${disableTextSelection}
  position: absolute;
  right: 28px;
  top: 5px;
`;
Wrapper.displayName = 'Wrapper';

export const ListViewIcon = styled(ListViewImage)`
  height: auto;
`;
ListViewIcon.displayName = 'ListViewIcon';
