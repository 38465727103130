import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { Edit } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import get from 'lodash/get';
import { selectRow, redirectToVulnTemplateDetail, unSelectAll } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectTemplatesSelected } from 'store/KnowledgeBase/selectors';

const EditButton = () => {
  const vulnTemplatesSelected = useSelector(selectTemplatesSelected);
  const vulnTemplate = get(vulnTemplatesSelected, '[0]', null);
  const vulnTemplateId = get(vulnTemplatesSelected, '[0].id', 0);
  const dispatch = useDispatch();

  const handleClick = (e) => {
    dispatch(unSelectAll());
    dispatch(redirectToVulnTemplateDetail(vulnTemplateId));
    dispatch(selectRow(e, vulnTemplate));
  };

  return (
    <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } />
  );
};

export default EditButton;
