import React from 'react';
import { useDispatch } from 'react-redux';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_AGENT_CREATE, MODAL_AGENT_TOKEN } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import TokenButton from './components/TokenButton';
import { Wrapper } from './styled';
import Token from './components/TokenButton/components/Token';
import CreateButton from './components/CreateButton';
import CreateAgentMenu from '../CreateNewAgent';

const ActionBar = ({ showCloudAgents }) => {
  const dispatch = useDispatch();
  return (
    <Wrapper>
        {
          !showCloudAgents &&
          <>
            <CreateButton />
            <TokenButton onClick={ () => dispatch(openModal(MODAL_AGENT_TOKEN)) } />
          </>
        }
      <ReduxModal id={ MODAL_AGENT_TOKEN }>
        <Token />
      </ReduxModal>
      <ReduxModal id={ MODAL_AGENT_CREATE }>
        <CreateAgentMenu onClose={ () => dispatch(closeModal(MODAL_AGENT_CREATE)) } />
      </ReduxModal>
    </Wrapper>
  );
};
export default ActionBar;
