import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  margin-right: ${(props) => props.context ? '21px' : '56px'};
  display: inline-flex;
`;
Wrapper.displayName = 'Wrapper';

export const RedAster = styled.span`
    color: #ea3158;
    bottom: 8px;
    position: relative;
    left: 2px;
`;
RedAster.displayName = 'RedAster';

export const SeverityName = styled.span`
  margin-left: 10px;
`;
