import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import { updateVulnFromAsset } from 'store/Contextualization/AssetDetail/actions';
import { selectPermissions } from 'store/Manage/selectors';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectReadOnly } from 'store/Faraday/selectors';

const TAB = 'general';
const ID = 'resolution';

const Resolution = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentVuln = useSelector((state) => selectDetail('vulns', state));
  const resolution = get(currentVuln, 'resolution', '');
  const updateValue = (field, value) => dispatch(updateVulnFromAsset(currentVuln, field, value));
  const isExpanded = useExpandable(TAB, ID, resolution);

  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const disabled = readOnly || !permissions.update.allowed;

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="resolution" title={ intl.formatMessage({ id: 'manage.detail.tab.general.resolution' }) } tab={ TAB }>
      <MarkdownEditor
        value={ resolution }
        onBlur={ (value) => updateValue('resolution', value) }
        disabled={ disabled }
        minHeight={ 170 }
      />
    </ExpandableWrapper>
  );
};

export default Resolution;
