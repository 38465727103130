import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateVuln } from 'store/Manage/actions';
import { selectReadOnly, selectPermissions } from 'store/Manage/selectors';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from '../GeneralTab/components/ExpandableWrapper';
import ToggleButtons from 'Screens/Contextualization/Manage/components/ToggleButtons';
import useExpandable from 'Hooks/useExpandable';
import { methodOptions } from 'Screens/Constants';
import StandardDropdown from 'Common/Components/StandarDropdown';
import StringField from 'Common/Components/StringField';
import { Wrapper, FieldWrapper } from './styled';

const TAB = 'technical';

const TechnicalTab = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vuln = useSelector((state) => selectDetail('vulns', state));
  const data = get(vuln, 'data', '');
  const website = get(vuln, 'website', '');
  const request = get(vuln, 'request', '');
  const response = get(vuln, 'response', '');
  const method = get(vuln, 'method', '');
  const path = get(vuln, 'path', '');
  const queryString = get(vuln, 'query', '');
  const statusCode = get(vuln, 'status_code', '');
  const parameterName = get(vuln, 'pname', '');
  const parameter = get(vuln, 'params', '');
  const isWeb = get(vuln, 'type', '') === 'VulnerabilityWeb';

  const isExpandedData = useExpandable(TAB, 'data', data);
  const isExpandedWebsite = useExpandable(TAB, 'website', website);
  const isExpandedRequest = useExpandable(TAB, 'request', request);
  const isExpandedResponse = useExpandable(TAB, 'response', response);
  const isExpandedMethod = useExpandable(TAB, 'method', method);
  const isExpandedPath = useExpandable(TAB, 'path', path);
  const isExpandedQueryString = useExpandable(TAB, 'queryString', queryString);
  const isExpandedStatusCode = useExpandable(TAB, 'statusCode', statusCode);
  const isExpandedParameterName = useExpandable(TAB, 'parameterName', parameterName);
  const isExpandedParameter = useExpandable(TAB, 'parameter', parameter);

  const updateValue = (field, value) => dispatch(updateVuln(vuln, field, value));

  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const disabled = readOnly || !permissions.update.allowed;

  return (
    <Wrapper>
      <ToggleButtons id="technical" />
       <ExpandableWrapper defaultValue={ isExpandedData } id="data" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.data' }) } tab={ TAB }>
        <MarkdownEditor
          value={ data }
          onBlur={ (value) => updateValue('data', value) }
          disabled={ disabled }
        />
       </ExpandableWrapper>
      {isWeb && (
        <>
         <ExpandableWrapper defaultValue={ isExpandedWebsite } id="website" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.website' }) } tab={ TAB }>
            <MarkdownEditor
              plainText
              value={ website }
              onBlur={ (value) => updateValue('website', value) }
              disabled={ disabled }
            />
         </ExpandableWrapper>
          <ExpandableWrapper defaultValue={ isExpandedRequest } id="request" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.request' }) } tab={ TAB }>
            <MarkdownEditor
              plainText
              value={ request }
              onBlur={ (value) => updateValue('request', value) }
              disabled={ disabled }
            />
          </ExpandableWrapper>
          <ExpandableWrapper defaultValue={ isExpandedResponse } id="response" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.response' }) } tab={ TAB }>
            <MarkdownEditor
              plainText
              value={ response }
              onBlur={ (value) => updateValue('response', value) }
              disabled={ disabled }
            />
          </ExpandableWrapper>
          <ExpandableWrapper defaultValue={ isExpandedMethod } id="method" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.method' }) } tab={ TAB }>
            <FieldWrapper>
              <StandardDropdown
                field="Method"
                defaultValue={ method }
                options={ methodOptions }
                updateValue={ (field, value) => updateValue('method', value) }
                placeholder="Method"
                width="154px"
                disabled={ disabled }
              />
            </FieldWrapper>
          </ExpandableWrapper>
          <ExpandableWrapper defaultValue={ isExpandedPath } id="path" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.path' }) } tab={ TAB }>
            <FieldWrapper>
              <StringField
                width="405px"
                height="38px"
                defaultValue={ path }
                onChange={ (value) => updateValue('path', value) }
              />
            </FieldWrapper>
          </ExpandableWrapper>
          <ExpandableWrapper defaultValue={ isExpandedQueryString } id="queryString" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.queryString' }) } tab={ TAB }>
            <MarkdownEditor
              plainText
              value={ queryString }
              onBlur={ (value) => updateValue('queryString', value) }
              disabled={ disabled }
            />
          </ExpandableWrapper>
          <ExpandableWrapper defaultValue={ isExpandedStatusCode } id="status_code" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.statusCode' }) } tab={ TAB }>
            <FieldWrapper>
              <StringField
                width="405px"
                height="38px"
                defaultValue={ statusCode }
                onChange={ (value) => updateValue('status_code', value) }
                type="number"
              />
            </FieldWrapper>
          </ExpandableWrapper>
          <ExpandableWrapper defaultValue={ isExpandedParameterName } id="parameterName" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.parameterName' }) } tab={ TAB }>
            <FieldWrapper>
              <StringField
                width="405px"
                height="38px"
                defaultValue={ parameterName }
                onChange={ (value) => updateValue('parameterName', value) }
              />
            </FieldWrapper>
          </ExpandableWrapper>
          <ExpandableWrapper defaultValue={ isExpandedParameter } id="parameter" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.parameter' }) } tab={ TAB }>
            <FieldWrapper>
              <StringField
                width="405px"
                height="38px"
                defaultValue={ parameter }
                onChange={ (value) => updateValue('parameter', value) }
              />
            </FieldWrapper>
          </ExpandableWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default TechnicalTab;
